
    .rwt__tabs[data-rwt-vertical="true"] {
        display: flex;
    }
  
    .rwt__tablist[aria-orientation="vertical"] {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        flex-grow: 0;
        border-right: 1px solid map-get($colors, main);
        margin-right: 1rem;
    }
  
    .rwt__tab {
        cursor: pointer;
        flex: 1; 
        width: 50%;
        color: darken(#fff, 15%);
        background: map-get($colors, third);
        border: 0;
        font-family: inherit;
        font-size: inherit;
        padding: 20px 20px 15px;
        transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
        height: map-get($heights, tab);
    }
  
    .rwt__tab[aria-selected="false"]:hover,
    .rwt__tab:focus {
        color: #fff;
        outline: 0;
        background-color: map-get($colors, third);
        opacity: 1;
    }
  
    .rwt__tab[aria-selected="true"] {
        position: relative;
        color: #fff;
        opacity: 1;
    }
  
    .rwt__tab[aria-selected="true"]:after {
        content: '';
        position: absolute;
    }
  
    .rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
        bottom: -1px;
        left: 0;
        width: 100%;
        border-bottom: 5px solid map-get($colors, main);
    }
  
    .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
        right: -1px;
        top: 0;
        height: 100%;
        border-right: 5px solid map-get($colors, main);
    }

.rwt__tabpanel {
    padding: 30px 30px 80px;
    &.fullheight {
        // $height: (map-get($heights, navBar ) + map-get($heights, tab ) + 60);
        height: calc(100vh - 210px);
        overflow: auto;
        &#two {
            height: calc(100vh - 100px);
        }
    }
}

.my-tabs {
    .wrapper {
        background: none;
    }
}
  