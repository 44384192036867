@import '../Styles/default.scss';

.scene.room-object-type {
    background: map-get($primaryColors, white)!important;
    h1 {
        text-align: center;
        margin-bottom: 5px;
    }
    h2 {
        text-align: center;
        margin-bottom: 25px;
    }
    > section {
        > h1 {
            margin-top: 20px;
        }
        > header, > .cardWrapper {
            width: 80%;
            margin: 0 auto;
            // min-height: 28vh;
        }
        > .CardWrapper {
            max-width: $container;
            margin: 0 auto;
            @media screen and (max-width: map-get($sizes, tablet)) {
                min-height: 50vh;
            }
        }
        > header {
            margin: 25px auto;
        }
    }

    .CardWrapper {
        padding: 20px 30px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        @media screen and (min-width: map-get($sizes, tablet)) {
            flex-direction: row;
        }
        > .Card {
            width: 100%;
            // @media screen and (min-width: map-get($sizes, tablet)) {
            //     width: 46%;
            //     margin-left: 2%;
            //     margin-right: 2%;
            // }
            @media screen and (min-width: map-get($sizes, tablet)) {
                width: 30%;
                margin-left: 1.5%;
                margin-right: 1.5%;
                flex-direction: column;
                > .picture {
                    height: 200px;
                    width: 100%;
                    // background-size: contain;
                    background-color: map-get($grey, medium);
                }
                > .title {
                    width: 100%;
                    > a {
                        padding: 20px 25px;
                        text-align: center;
                    }
                }
            }
        }
    }
}
