@import '../Styles/default.scss';

.CardWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @media screen and (min-width: map-get($sizes, tablet)) {
        flex-direction: row;
    }
    > .Card {
        width: 100%;
        @media screen and (min-width: map-get($sizes, tablet)) {
            width: 46%;
            margin-left: 2%;
            margin-right: 2%;
        }
        @media screen and (min-width: map-get($sizes, large)) {
            width: 30%;
            margin-left: 1.5%;
            margin-right: 1.5%;
        }
    }
}

.Card {
    margin-bottom: 30px;
    transition: 0.2s all ease-in;
    > .picture {
        background-position: center center;
        background-repeat: no-repeat;
        a {
            display: block;
            height: 100%;
        }
        img {
            visibility: hidden;
            max-height: 100%;
            max-width: 100%;
        }
    }
    > .title {
        > a {
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: 1px;
            padding: 8px 10px;
            display: block;
            font-weight: bold;
        }
    }
    &.big {
        > .picture {
            height: 200px;
        }
        > .title {
            > a {
                text-align: center;
            }
        }
    }
    &.small, &.tiny {
        display: flex;
        flex-flow: row;
        align-items: center;
        > * {
            width: 100%;
        }
        > .picture {
            width: 25%;
            height: 70px;
        }
        > .title {
            width: 75%;
            font-size: 0.9em;
            line-height: 1.2em;
        }
    }

    &.is-icon {
        > .picture {
            width: 40px;
            height: 70px;
        }
        > .title {
            width: calc(100% - 40px);
        }
    }

    &.tiny > .picture { height: 50px; }

    &.covered-background { > .picture { background-size: cover; } }
    &.contained-background { > .picture { background-size: contain; } }
    &.is-icon { > .picture { background-size: 15px!important; background-position: 80% 45%; } }
    &.colored-background { > .picture { background-color: map-get($colors, third); } > .title > a { color: #fff; background: map-get($colors, fourth); } }
    &.uncolored-background { box-shadow: 0px 2px 10px rgba(0,0,0,0.25); background-color: #FFFFFF; > .picture { background-color: #FFFFFF; } > .title > a { color: map-get($colors, third); background: #ffffff; } }
    &.fade { opacity: 0.4; }
}
