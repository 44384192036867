@import '../Styles/default.scss';

.scene.splash-screen {
    padding-bottom: 0px;
    padding-top: 0;
    background: map-get($colors, secondary)!important;
    > section {
        background: map-get($colors, secondary);
        #loading {
            max-height: 50vh;
            background-image: url(/gfx/logo/white_logo.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 260px;
        }
    }
}
