@import '../Styles/default.scss';

.page.error-404 {
    background: map-get($colors, secondary)!important;
}

.scene.error-404 > section {
    background: map-get($colors, secondary);
    padding-bottom: 0px;
    > * {
        padding-left: 30px;
        padding-right: 30px;
    }
    > header {
        background: map-get($colors, secondary);
        padding: 50px 30px 0px;
        img {
            max-width: 270px;
            display: block;
            margin: 0 auto;
            margin-bottom: 25px;
        }
    }
    > .content {
        max-width: 800px;
        margin: 0 auto;
        h1 {
            margin-bottom: 20px;
        }
        p, li, td {
            font-size: 1.1em;
            color: map-get($primaryColors, white);
        }
    }
     
}
