@import '../Styles/default.scss';

.page {
    height: 100vh;
    padding: (map-get($heights, navBar)) 0% (map-get($heights, bottomBar)+ 30px);
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    transition: transform 0.25s ease-in-out;
    
    > section {
        max-width: 100%;
        overflow: auto;
        margin: 0 auto;
        padding-bottom: 50px;
        &.start-with-title {
            padding-top: 30px;
        }
        h1 {
            color: map-get($colors, main);
            font-family: map-get($fonts, title);
            font-weight: bold;
            font-size: 1.6em;
            line-height: 1.25em;
        }

        h2 {
            color: map-get($colors, fifth);
            font-weight: 400;
            font-size: 1.15em;
            line-height: 1.25em;
        }
        p, li, td {
            font-family: map-get($fonts, copy);
            font-weight: 400;
            font-size: 1em;
        }
    }

    &-enter {
        transform: translate(100%, 0);
        &-active {
            transform: translate(0, 0);
        }
    }

    &-exit {
        transform: translate(-100%, 0);
    }

    &--prev {
        &.page-enter {
            transform: translate(100%, 0);
        }

        &.page-enter-active {
            transform: translate(0, 0);
        }
        
        &.page-exit {
            transform: translate(100%, 0);
        }
    }
}


