@import url('https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i');

$browser-context: 14;
// Greyscale colors
$grey: (
    dark: #4e4e4e,
    regular: #888888, 
    medium: #d2d2d2,
    light: #F0F0F0
)!default;

// General common colors
$primaryColors: (
    orange: #FF8202,
    blue: #2c4173,
    yellow: #ffd211,
    black: #000000,
    white: #FFFFFF,
    green: #4ADB11,
    red: #D2001A,
    magenta: #e8165d,
    pink: #FB6666
)!default;

$heights : (
    navBar: 50px,
    bottomBar: 50px,
    tab: 50px
)!default;

// Sizes
$margin: 20px;
$container: 1200px;
$layoutContainer: 90%;

$sizes : (
    smarthpone: 480px,
    tablet: 768px,
    large: 1200px
)!default;

// Specific color for the theme
$colors: (
    main: #c3a77a,
    secondary: #21283d,
    third: #2c3551,
    fourth: #565d74,
    fifth: #808697
)!default;

// Default social network colors
$social-network-colors: (
    linkedin: #0077B5,
    twitter: #08A0E9,
    facebook: #3C5898,
    share: #4D4741
) !default;

// Define fonts for the theme
$fonts: (
    title: ('Merriweather', serif),
    copy: ('Roboto', sans-serif)
)!default;