@import '../Styles/default.scss';

footer.Footer {
    &.blue { 
        background: map-get($colors, secondary); 
        padding-bottom: 35px;
        > div > .TimerWrapper {
            color: map-get($primaryColors, white);
            strong { color: map-get($primaryColors, white); }
        }
    }
    &.white { 
        background: map-get($primaryColors, white); 
        > div > .TimerWrapper {
            color: map-get($grey, regular);
            strong { color: map-get($grey, dark ); }
        }
    }
    min-height: 100px;
    margin-top: 70px;
    > div {
        max-width: 80%;
        margin: 0 auto;
        .ButtonWrapper {
            text-align: center;
            .Button {
                padding-top: 10px;
                padding-bottom: 10px;
            }
            position: relative;
            top: -20px;
        }
        .TimerWrapper {
            padding-top: 15px;
            padding-bottom: 70px;
            text-align: center;
            font-size: 1.1em;
        }
    }
}
