body {
    line-height: 1.45em;
    font-size: 14px;
    color: map-get($grey, dark);
    font-family: sans-serif;
    background: map-get($colors, secondary);
}

*[data-appear] { opacity: 0;}

html {
    /* Adjust font size */
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    background: map-get($colors, third);
}

img {
    max-width: 100%;
}

strong {
    font-weight: bold;
}

.appear-fade-in {
    animation: fadeIn 0.35s;
}

.text-center {
    text-align: center;
}

.nothing {
    color: map-get($colors,third );
    font-size: 1.1em!important;
}

@keyframes fadeIn {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

#screen {
    max-width: 375px;
    max-height: 667px;
    // overflow: auto;
}
