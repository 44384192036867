@import '../Styles/default.scss';

.nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  height: map-get($heights, navBar );
  background: map-get($colors, third);
  box-shadow: 0px 0px 5px rgba(0,0,0,0.7);
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 80px;
    height: 15px;
    left: 15px;
    top: 15px;
    // &:after {
    //     content: "MENU";
    //     color: #fff;
    //     position: fixed;
    //     top: 15px;
    //     margin-left: 10px;
    //     font-size: 1em;
    // }
}
  
  /* Color/shape of burger icon bars */
.bm-burger-bars {
    
}
  
  /* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    
}
  
  /* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}
  
  /* Color/shape of close button cross */
.bm-cross {
    background: map-get($colors, main);
}
.bm-menu-wrap {
  position: fixed;
  height: 100vh;
}
  
  /* General sidebar styles */
.bm-menu {
  background: map-get($colors, secondary);
  font-size: 1.15em;
  max-height: 100vh;
} 
  /* Wrapper for item list */
.bm-item-list {
    max-height: 100vh;
}
  
.bm-item {
  padding: 15px 30px;
  &.logo {
    background: map-get($colors, third);
    max-height: 200px;
    padding-top: map-get($heights, navBar);
    text-align: center;
    outline: 0;
    img {
      max-width: 95%;
    } 
  }
  &.items {
    padding: 25px 30px;
    > .menu-item {
      font-size: 1em;
      display: block;
      color: #fff;
      outline: none;
      margin-bottom: 30px;
      text-decoration: none;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      > img {
        max-width: 15px;

      }
      > span {
        margin-left: 20px;
      }
    }
  }
}

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
