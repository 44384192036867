@import '../Styles/default.scss';

.page.choice-screen {
    background: map-get($colors, secondary)!important;
    z-index: 10000;
    color: #fff;
    > section {
        > header {
            background: map-get($colors, secondary);
            padding: 50px 30px 0px;
            img {
                max-width: 150px;
                display: block;
                margin: 0 auto;
                margin-bottom: 25px;
            }
        }
        max-width: 100%;
        overflow: auto;
        margin: 0 auto;
        padding-bottom: 60px;
        color: #fff;
        .react-code-input {
            input {
                background: transparent;
                border: 0;
            }
        }
        h1 {
            color: #fff;
            max-width: 240px;
            margin: 70px auto 0;
            text-align: center;
        }
        .choices {
            max-width: 80%;
            margin: 30px auto;
            text-align: center;
            .Button {
                max-width: 95%;
                margin: 0px 10px 20px;
                min-width: 200px;
                text-align: center;
            }
        }
        // .CodePinWrapper {
        //     border: 2px solid map-get($colors, main);
        //     width: 60%;
        //     max-width: 300px;
        //     text-align: center;
        //     margin: 20px auto 30px;
        //     padding: 10px 20px 15px;
        // }
        // form {
        //     text-align: center;
        // }
        .Button {
            margin: 0 auto;
            font-size: 1.15em;
            cursor: pointer;
            color: #fff;
            &:hover {
                background: #fff;
                color: map-get($colors, main)!important;
            }
        }
    }
}
