@import '../Styles/default.scss';

.scene.rooms {
    background: map-get($colors, secondary)!important;
    h1 {
        text-align: center;
        margin-bottom: 5px;
    }
    h2 {
        text-align: center;
        margin-bottom: 25px;
    }
    > section { padding-bottom: 0px; }
    // .rwt__tab  {
        // width: 100%;
    // } 
    #two {
        padding: 0;
        > div {

        }
        .panzoom-elements {
            // margin-top: 80px;
        }
        .actions {
            position: absolute;
            z-index: 1000;
            > a {
                background: #fff;
                color: map-get($colors, main);
                text-decoration: none;
                display: block;
                width: 25px;
                height: 25px;
                text-align: center;
                line-height: 25px;
                font-weight: bold;
                font-size: 2em;
                margin: 10px;
            }
        }
    }
}
