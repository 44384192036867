@import '../Styles/default.scss';

.Picture {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100px;
    img {
        visibility: hidden;
        max-height: 100%;
        max-width: 100%;
    }
}
