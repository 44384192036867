.Button, a.Button, .gform_button {
	&, &input[type=submit], &button {
		+ .Button {
			margin-left: 15px;
		}
		outline: none;
		transition: 0.2s all ease-in;
		border: 0px;
		display: inline-block;
		padding: 5px 20px 5px 20px;
		text-transform: uppercase;
		background: map-get($colors, main);
		color: #fff!important;
		text-decoration: none;
		text-align: left;
		font-family: map-get($fonts, copy);
		font-weight: 500;
		border-radius: 1px;
        // font-size: 0.85em;
        letter-spacing: 1px;
		border-radius: 0px;
		font-weight: 900;
		@media screen and (max-width: 500px) {
			display: block;
			text-align: center;
			// width: 100%;
			+ .Button {
				margin-left: 0;
				margin-top: 20px;
			}
		}

		&.box-shadow {
			box-shadow: 0px 0px 5px rgba(0,0,0,0.35);
		}

		&.full {
			display: block;
			max-width: auto;
			width: 100%!important;
			padding: 10px 10px;
			text-align: center;
		}
		&.sm-small {
			@media screen and (max-width: 48em) {
				font-size: 0.9em;
				padding: 8px 10px 8px 10px;
				margin-left: 10px;
			}
		}
		&.small {
			font-size: 0.9em;
			padding: 8px 10px 8px 10px;
		}
		&.bordered {
			border: 3px solid #fff; 
			outline: 3px solid map-get($colors, main);
			margin-top: 3px;
			&:hover {
				outline: 3px solid map-get($colors, secondary);
			}
		}
		&.no-rounded {
			border-radius: 0;
			padding: 8px 20px;
			&.small {
				padding: 8px 10px;
			}
		}
		&.no-background {
			background: none;
			color: map-get($colors, main)!important;
			&:hover,  &.active {
				color: #141414!important;
				background: none;
			}
		}
		&:hover, &.active {
			background: map-get($colors, secondary);
		}
		&.full {
			width: 77%;
		}
		&.is-fullwidth {
			width: 100%;
		}
		&.grey {
			color: map-get($grey, regular)!important;
			&:hover, &.active {
				color: #141414!important;
			}
		}
		&.revert {
			background: map-get($colors, secondary);
			&:hover,  &.active {
				background: map-get($colors, main);
			}
		}
		&.highlight {
			background: map-get($primaryColors, magenta);
			color: #fff!important;
			&:hover,  &.active {
				color: map-get($primaryColors, magenta);
				background: #fff;
			}
		}
		&.is-completed {
			background: map-get($colors, secondary);
			color: #fff!important;
		}
		&.white {
			background: #fff;
			color: map-get($colors, main)!important;
			&:hover,  &.active {
				background: #141414;
				color: #fff!important;
				&.go-to-outline {
					border: 2px solid #fff;
					color: #fff!important;
					background: rgba(255,255,255,0);
				}
			}
		}
		&.dark {
			background: #333333;
			color: #ffffff!important;
			border: 1px solid #333333;
			&:hover {
				color: #333333!important;
				background: rgba(0,0,0,0);
			}
		}
		&.go-to-outline {
			border: 2px solid transparent;
		}
		&.go-dark {
			&:hover,  &.active {
				background: #141414;
				color: #fff!important;
			}
		}
		&.go-light {
			&:hover,  &.active {
				background: #fff;
				color: #141414!important;
			}
		}
		&.go-primary {
			&:hover,  &.active {
				background: map-get($colors, main);
				color: #fff!important;
			}
		}
		&.go-secondary {
			&:hover,  &.active {
				background: map-get($colors, secondary);
				color: #fff!important;
			}
		}
		&.facebook {
			background: #415e9e!important;
			color: #fff!important;
			border: 2px solid #415e9e;
			&:hover, &:focus {
				background: #fff!important;
				color: #415e9e!important;
			}
		}
		&.fat {
			padding: 10px 25px;
		}
		&.has-border {
			border: 2px solid map-get($primaryColors, white);
		}
		&.outline {
			background: none;
			border: 2px solid map-get($colors, main);
			color: map-get($colors, main)!important;
			border-radius: 0px;
			&:hover,  &.active {
				background: map-get($colors, main);
				color: #fff!important;
			}
			&.revert {
				border-color: map-get($colors, secondary);
				color: map-get($colors, secondary)!important;
				&:hover,  &.active {
					background: map-get($colors, secondary);
					color: #fff!important;
				}
			}
			&.grey {
				border-color: map-get($grey, medium);
				color: map-get($grey, medium)!important;
				&:hover, &.active {
					background: map-get($grey, medium);
					color: #141414!important;
				}
			}
			&.highlight {
				border-color: map-get($primaryColors, magenta);
				color: map-get($primaryColors, magenta)!important;
				&:hover, &.active {
					background: map-get($primaryColors, magenta);
					color: #fff!important;
				}
			}
			&.dark {
				border-color: #333333;
				color: #333333!important;
				&:hover {
					background: #333333;
					color: #fff!important;
				}
			}
			&.white {
				border-color: #fff;
				color: #fff!important;
				&:hover,  &.active {
					background: #fff;
					color: #141414!important;
				}
			}
			&.is-completed {
				border-color: map-get($colors, secondary);
				color: map-get($colors, secondary)!important;
				&:hover, &.active {
					background: map-get($colors, secondary);
					color: #fff!important;
				}
			}
			&.go-dark {
				&:hover,  &.active {
					background: #141414;
					border-color: #141414;
					color: #fff!important;
				}
			}
			&.go-light {
				&:hover,  &.active {
					border-color: #fff;
					background: #fff;
					color: #141414!important;
				}
			}
			&.go-primary {
				&:hover,  &.active {
					border-color: map-get($colors, main);
					background: map-get($colors, main);
					color: #fff!important;
				}
			}
			&.go-secondary {
				&:hover,  &.active {
					border-color: map-get($colors, secondary);
					background: map-get($colors, secondary);
					color: #fff!important;
				}
			}
		}
		&.icon-only {
			background: none;
			color: map-get($colors, main)!important;
			padding: 0;
			.pxm-icon {
				margin-left: 0;
			}
			&:hover, &.active {
				color: map-get($colors, secondary)!important;
			}

			&.revert {
				color: map-get($colors, secondary)!important;
				&:hover, &.active {
					background: none;
					color: map-get($colors, main)!important;
				}
			}
			&.highlight {
				color: map-get($primaryColors, magenta);
				&:hover, &.active {
					background: none;
					color: map-get($primaryColors, magenta)!important;
				}
			}
			&.go-dark {
				&:hover,  &.active {
					background: none;
					color: #141414!important;
				}
			}
			&.go-light {
				&:hover,  &.active {
					background: none;
					color: #fff!important;
				}
			}
			&.go-primary {
				&:hover,  &.active {
					background: none;
					color: map-get($colors, main)!important;
				}
			}
			&.go-secondary {
				&:hover,  &.active {
					background: none;
					color: map-get($colors, secondary)!important;
				}
			}
		}
	}
}
