@import '../Styles/default.scss';

.scene.room-object-identity {
    // background: map-get($colors, secondary)!important;
    &.has-audio {
        .AudioWrapper {
            display: block;
        }
        footer {
            margin-bottom: 140px;
            @media screen and (min-width: map-get($sizes, tablet)) {
                margin-bottom: 0;
            }
        }
    }
    footer {
        margin-bottom: 30px;
    }
    @media screen and (min-width: map-get($sizes, tablet)) {
        footer {
            margin-bottom: 0px;
            > div {
                max-width: 95%;
            }
            .ButtonWrapper {
                text-align: left;
                top: 20px;
                // left: -10%;
            }
        }
    }
    .AudioWrapper {
        display: none;
        background: map-get($colors, secondary);
        position: fixed;
        bottom: 0px;
        z-index: 100;
        width: 100%;
        @media screen and (min-width: map-get($sizes, tablet)) {
            position: absolute;
            bottom: auto;
            margin-top: 87vh;
            width: 50%!important;
            margin-left: 50%;
        }
        > div {
            padding: 10px 20px;
            @media screen and (min-width: map-get($sizes, tablet)) {
                padding: 0px 20px 15px;
            }
            .plyr__controls {
                background: transparent;
                color: #fff;
            }
        }
    }
    background: #fff;
    h1 {
        text-align: center;
        margin-bottom: 5px;
    }
    h2 {
        text-align: center;
        margin-bottom: 25px;
    }

    .puzzleHolder {
        background-color: map-get($grey, medium);
        padding: 20px 0 30px;
        > div {
            display: flex;
            align-items: center;
            height: 100%;
            > div {
                margin: 0 auto;
            }
        }

        @media screen and (min-width: map-get($sizes, tablet)) {
            height: 86vh;
        }
    } 

    .ContentWrapper {
        display: flex;
        flex-direction: column;
        @media screen and (min-width: map-get($sizes, tablet)) {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            > * {
                width: 50%;
            }
        }
        .my-tabs {
            height: 43vh;
            @media screen and (min-width: map-get($sizes, tablet)) {
                height: 86vh;
            }
            .Picture {
                height: 42vh;
                @media screen and (min-width: map-get($sizes, tablet)) {
                    height: 86vh;
                    background-size: contain;
                }
                background-size: auto 95%;
                background-color: map-get($grey, medium);
            }
            &[data-video="true"] {
                @media screen and (min-width: map-get($sizes, tablet)) {
                    height: 70vh;
                    .Picture {
                        height: 82vh;
                    }
                    .wrapper {
                        .rwt__tabpanel {
                            // height: 100%;
                            height: 82vh;
                        }
                    }
                }
                height: 50vh;
            }
            .wrapper {
                .rwt__tabpanel {
                    height: 40vh;
                    padding: 0px;
                    background-color: map-get($grey, medium);
                    &#two {
                        background: #000;
                        > div {
                            height: 100%;
                            display: flex;
                            align-items: center;
                        }
                        .plyr {
                            width: 100%;
                        }
                    }
                    iframe {
                        height: 35vh;
                    }
                }
            }
        }

        .content {
            background: #fff;
            display: flex;
            align-items: center;
            @media screen and (min-width: map-get($sizes, tablet)) {
                height: 86vh;
                overflow: auto;
            }
            > div { 
                padding: 20px 30px 0;
                @media screen and (min-width: map-get($sizes, tablet)) {
                    max-height: 85%;
                    overflow: auto;
                    padding: 30px 80px;
                    line-height: 1.3em;
                    > h1 {
                        // padding-top: 80px;
                    }
                }
                h1, h2 {
                    text-align: left;
                    margin-bottom: 25px;
                }

                p, li {
                    color: map-get($colors, fourth);
                    font-size: 1.1em;
                    margin-bottom: 30px;
                }
            }
        }
    }

    footer.Footer.white {
        @media screen and (min-width: map-get($sizes, tablet)) {
            position: relative;
            z-index: 99;
            margin-top: 0;
            background: map-get($colors, secondary); 
            > div > .TimerWrapper {
                color: map-get($primaryColors, white);
                strong { color: map-get($colors, main ); }
            }
        }
    }

    /* required snapPuzzle styles */
    .snappuzzle-wrap { position: relative; display: block; }
    .snappuzzle-pile { position: relative; }
    .snappuzzle-piece { cursor: move; }
    .snappuzzle-slot { position: absolute; background: #fff; opacity: .8; }
    .snappuzzle-slot-hover { background: #eee; }
}
