@import '../Styles/default.scss';

.scene.room {
    background: map-get($primaryColors, white)!important;

    h1 {
        text-align: center;
        margin-bottom: 5px;
    }
    h2 {
        text-align: center;
        margin-bottom: 25px;
    }

    > section {
        > header {
            > h1 {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 20px;
                text-align: left;
            }
            .Picture {
                height: 250px;
                @media screen and (min-width: map-get($sizes, tablet)) {
                    height: 51vh;
                }
            }
        }
        > section {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            padding: 15px 0px;
            > .introduction {
                color: map-get($colors, fourth );
                font-size: 1.1em;
                line-height: 1.2em;
                margin-bottom: 45px;
            }

            > .CardWrapper {

            }
        }
    }
}
