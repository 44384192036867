@import '../Styles/default.scss';

.Map {
    background: 'url(/gfx/map.jpg) center center no-repeat;'; 
    background-size: "contain";
    width: 100%;
    height: calc(100vh - 130px);
    overflow: auto;
    display: flex;
    align-items: center;
    background-color: #F0F0F0;

    .imp-shape-text {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 10px!important;
        @media screen and (min-width: 300px) { font-size: 5px!important; }
        @media screen and (min-width: 500px) { font-size: 7px!important; }
        @media screen and (min-width: 800px) { font-size: 12px!important; }
        @media screen and (min-width: 900px) { font-size: 13px!important; }
        @media screen and (min-width: 1000px) { font-size: 14px!important; }
        @media screen and (min-width: 1200px) { font-size: 15px!important; }
        @media screen and (min-width: 1600px) { font-size: 15px!important; }
        line-height: 1em;
        color: map-get($colors, secondary);
        // position: relative;
        // z-index: 5;
    }
    
    // svg {
        // position: relative;
        
    // }
    .imp-shape-poly {
        // z-index: 6;
        $color: map-get($colors, main);
        fill: rgba(red($color), green($color), blue($color), 0.5)!important;
    }
}
