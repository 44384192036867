@import '../Styles/default.scss';

.scene.options > section {
    background: map-get($colors, secondary);
    padding-bottom: 0px;
    > * {
        padding-left: 30px;
        padding-right: 30px;
    }
    > header {
        background: map-get($colors, secondary);
        padding: 50px 30px 0px;
        img {
            max-width: 270px;
            display: block;
            margin: 0 auto;
            margin-bottom: 25px;
        }
        h1 {
            margin-bottom: 20px;
            text-align: center;
        }
        > .content {
            max-width: 290px;
            margin: 0 auto;
            p, li, td {
                font-size: 1.1em;
                color: map-get($primaryColors, white);
            }

            h2 {
                color: #fff;
                margin-bottom: 10px;
            }

            .Buttons {
                // text-align: center;
                .Button {
                    // max-width: 30px;
                    display: inline-block;
                    margin: 2px 3px;
                    &.selected {
                        background: map-get($colors, main);
                        color: map-get($colors, secondary )!important;
                    }
                }
                & + h2 {
                    margin-top: 25px;
                }
            }
        }
    }
}
