@import "./reset";
@import "./config";
@import "./button";
@import "./global";
@import "./tabs";
@import "./../Component/Plyr";
@import "./../external/image-pro.scss";
// @import '../../node_modules/plyr/src/sass/plyr';

html,
body {
    overscroll-behavior-y: contain;
}