@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i|Roboto:400,400i,700,700i);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.page {
  height: 100vh;
  padding: 50px 0% 80px;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }
  .page > section {
    max-width: 100%;
    overflow: auto;
    margin: 0 auto;
    padding-bottom: 50px; }
    .page > section.start-with-title {
      padding-top: 30px; }
    .page > section h1 {
      color: #c3a77a;
      font-family: "Merriweather", serif;
      font-weight: bold;
      font-size: 1.6em;
      line-height: 1.25em; }
    .page > section h2 {
      color: #808697;
      font-weight: 400;
      font-size: 1.15em;
      line-height: 1.25em; }
    .page > section p, .page > section li, .page > section td {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 1em; }
  .page-enter {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0); }
    .page-enter-active {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); }
  .page-exit {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0); }
  .page--prev.page-enter {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0); }
  .page--prev.page-enter-active {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .page--prev.page-exit {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0); }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.scene.home > section {
  background: #21283d;
  padding-bottom: 0px; }
  .scene.home > section > * {
    padding-left: 30px;
    padding-right: 30px; }
  .scene.home > section > header {
    background: #21283d;
    padding: 50px 30px 0px; }
    .scene.home > section > header img {
      max-width: 270px;
      display: block;
      margin: 0 auto;
      margin-bottom: 25px; }
  .scene.home > section > .content {
    max-width: 800px;
    margin: 0 auto; }
    .scene.home > section > .content h1 {
      margin-bottom: 20px; }
    .scene.home > section > .content p, .scene.home > section > .content li, .scene.home > section > .content td {
      font-size: 1.1em;
      color: #FFFFFF; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

footer.Footer {
  min-height: 100px;
  margin-top: 70px; }
  footer.Footer.blue {
    background: #21283d;
    padding-bottom: 35px; }
    footer.Footer.blue > div > .TimerWrapper {
      color: #FFFFFF; }
      footer.Footer.blue > div > .TimerWrapper strong {
        color: #FFFFFF; }
  footer.Footer.white {
    background: #FFFFFF; }
    footer.Footer.white > div > .TimerWrapper {
      color: #888888; }
      footer.Footer.white > div > .TimerWrapper strong {
        color: #4e4e4e; }
  footer.Footer > div {
    max-width: 80%;
    margin: 0 auto; }
    footer.Footer > div .ButtonWrapper {
      text-align: center;
      position: relative;
      top: -20px; }
      footer.Footer > div .ButtonWrapper .Button {
        padding-top: 10px;
        padding-bottom: 10px; }
    footer.Footer > div .TimerWrapper {
      padding-top: 15px;
      padding-bottom: 70px;
      text-align: center;
      font-size: 1.1em; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

#loading {
  height: 100vh; }
  #loading .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto; }
  #loading .double-bounce1, #loading .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #c3a77a;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out; }
  #loading .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s; }

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.page.lock-screen {
  background: #21283d !important;
  color: #fff; }
  .page.lock-screen > section {
    max-width: 100%;
    overflow: auto;
    margin: 0 auto;
    padding-bottom: 60px;
    color: #fff; }
    .page.lock-screen > section > header {
      background: #21283d;
      padding: 50px 30px 0px; }
      .page.lock-screen > section > header img {
        max-width: 150px;
        display: block;
        margin: 0 auto;
        margin-bottom: 25px; }
    .page.lock-screen > section .react-code-input input {
      background: transparent;
      border: 0; }
    .page.lock-screen > section h1 {
      color: #fff;
      max-width: 240px;
      margin: 70px auto 0;
      text-align: center; }
    .page.lock-screen > section .error {
      width: 60%;
      border: 2px solid #FB6666;
      color: #FB6666;
      max-width: 300px;
      text-align: center;
      margin: 20px auto 30px;
      padding: 10px 20px 15px; }
    .page.lock-screen > section .CodePinWrapper {
      border: 2px solid #c3a77a;
      width: 60%;
      max-width: 300px;
      text-align: center;
      margin: 20px auto 30px;
      padding: 10px 20px 15px; }
    .page.lock-screen > section form {
      text-align: center; }
    .page.lock-screen > section .Button {
      margin: 0 auto;
      font-size: 1.15em;
      cursor: pointer; }
      .page.lock-screen > section .Button:hover {
        background: #fff;
        color: #c3a77a !important; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.page.choice-screen {
  background: #21283d !important;
  z-index: 10000;
  color: #fff; }
  .page.choice-screen > section {
    max-width: 100%;
    overflow: auto;
    margin: 0 auto;
    padding-bottom: 60px;
    color: #fff; }
    .page.choice-screen > section > header {
      background: #21283d;
      padding: 50px 30px 0px; }
      .page.choice-screen > section > header img {
        max-width: 150px;
        display: block;
        margin: 0 auto;
        margin-bottom: 25px; }
    .page.choice-screen > section .react-code-input input {
      background: transparent;
      border: 0; }
    .page.choice-screen > section h1 {
      color: #fff;
      max-width: 240px;
      margin: 70px auto 0;
      text-align: center; }
    .page.choice-screen > section .choices {
      max-width: 80%;
      margin: 30px auto;
      text-align: center; }
      .page.choice-screen > section .choices .Button {
        max-width: 95%;
        margin: 0px 10px 20px;
        min-width: 200px;
        text-align: center; }
    .page.choice-screen > section .Button {
      margin: 0 auto;
      font-size: 1.15em;
      cursor: pointer;
      color: #fff; }
      .page.choice-screen > section .Button:hover {
        background: #fff;
        color: #c3a77a !important; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.scene.options > section {
  background: #21283d;
  padding-bottom: 0px; }
  .scene.options > section > * {
    padding-left: 30px;
    padding-right: 30px; }
  .scene.options > section > header {
    background: #21283d;
    padding: 50px 30px 0px; }
    .scene.options > section > header img {
      max-width: 270px;
      display: block;
      margin: 0 auto;
      margin-bottom: 25px; }
    .scene.options > section > header h1 {
      margin-bottom: 20px;
      text-align: center; }
    .scene.options > section > header > .content {
      max-width: 290px;
      margin: 0 auto; }
      .scene.options > section > header > .content p, .scene.options > section > header > .content li, .scene.options > section > header > .content td {
        font-size: 1.1em;
        color: #FFFFFF; }
      .scene.options > section > header > .content h2 {
        color: #fff;
        margin-bottom: 10px; }
      .scene.options > section > header > .content .Buttons .Button {
        display: inline-block;
        margin: 2px 3px; }
        .scene.options > section > header > .content .Buttons .Button.selected {
          background: #c3a77a;
          color: #21283d !important; }
      .scene.options > section > header > .content .Buttons + h2 {
        margin-top: 25px; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.CardWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  @media screen and (min-width: 768px) {
    .CardWrapper {
      flex-direction: row; } }
  .CardWrapper > .Card {
    width: 100%; }
    @media screen and (min-width: 768px) {
      .CardWrapper > .Card {
        width: 46%;
        margin-left: 2%;
        margin-right: 2%; } }
    @media screen and (min-width: 1200px) {
      .CardWrapper > .Card {
        width: 30%;
        margin-left: 1.5%;
        margin-right: 1.5%; } }

.Card {
  margin-bottom: 30px;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in; }
  .Card > .picture {
    background-position: center center;
    background-repeat: no-repeat; }
    .Card > .picture a {
      display: block;
      height: 100%; }
    .Card > .picture img {
      visibility: hidden;
      max-height: 100%;
      max-width: 100%; }
  .Card > .title > a {
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 8px 10px;
    display: block;
    font-weight: bold; }
  .Card.big > .picture {
    height: 200px; }
  .Card.big > .title > a {
    text-align: center; }
  .Card.small, .Card.tiny {
    display: flex;
    flex-flow: row;
    align-items: center; }
    .Card.small > *, .Card.tiny > * {
      width: 100%; }
    .Card.small > .picture, .Card.tiny > .picture {
      width: 25%;
      height: 70px; }
    .Card.small > .title, .Card.tiny > .title {
      width: 75%;
      font-size: 0.9em;
      line-height: 1.2em; }
  .Card.is-icon > .picture {
    width: 40px;
    height: 70px; }
  .Card.is-icon > .title {
    width: calc(100% - 40px); }
  .Card.tiny > .picture {
    height: 50px; }
  .Card.covered-background > .picture {
    background-size: cover; }
  .Card.contained-background > .picture {
    background-size: contain; }
  .Card.is-icon > .picture {
    background-size: 15px !important;
    background-position: 80% 45%; }
  .Card.colored-background > .picture {
    background-color: #2c3551; }
  .Card.colored-background > .title > a {
    color: #fff;
    background: #565d74; }
  .Card.uncolored-background {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF; }
    .Card.uncolored-background > .picture {
      background-color: #FFFFFF; }
    .Card.uncolored-background > .title > a {
      color: #2c3551;
      background: #ffffff; }
  .Card.fade {
    opacity: 0.4; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.scene.rooms {
  background: #21283d !important; }
  .scene.rooms h1 {
    text-align: center;
    margin-bottom: 5px; }
  .scene.rooms h2 {
    text-align: center;
    margin-bottom: 25px; }
  .scene.rooms > section {
    padding-bottom: 0px; }
  .scene.rooms #two {
    padding: 0; }
    .scene.rooms #two .actions {
      position: absolute;
      z-index: 1000; }
      .scene.rooms #two .actions > a {
        background: #fff;
        color: #c3a77a;
        text-decoration: none;
        display: block;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        font-weight: bold;
        font-size: 2em;
        margin: 10px; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.Map {
  background: 'url(/gfx/map.jpg) center center no-repeat;';
  background-size: "contain";
  width: 100%;
  height: calc(100vh - 130px);
  overflow: auto;
  display: flex;
  align-items: center;
  background-color: #F0F0F0; }
  .Map .imp-shape-text {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px !important;
    line-height: 1em;
    color: #21283d; }
    @media screen and (min-width: 300px) {
      .Map .imp-shape-text {
        font-size: 5px !important; } }
    @media screen and (min-width: 500px) {
      .Map .imp-shape-text {
        font-size: 7px !important; } }
    @media screen and (min-width: 800px) {
      .Map .imp-shape-text {
        font-size: 12px !important; } }
    @media screen and (min-width: 900px) {
      .Map .imp-shape-text {
        font-size: 13px !important; } }
    @media screen and (min-width: 1000px) {
      .Map .imp-shape-text {
        font-size: 14px !important; } }
    @media screen and (min-width: 1200px) {
      .Map .imp-shape-text {
        font-size: 15px !important; } }
    @media screen and (min-width: 1600px) {
      .Map .imp-shape-text {
        font-size: 15px !important; } }
  .Map .imp-shape-poly {
    fill: rgba(195, 167, 122, 0.5) !important; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.scene.games {
  background: #FFFFFF !important; }
  .scene.games h1 {
    text-align: center;
    margin-bottom: 5px; }
  .scene.games h2 {
    text-align: center;
    margin-bottom: 25px; }
  .scene.games > section > h1 {
    margin-top: 20px; }
  .scene.games > section > header, .scene.games > section > .cardWrapper {
    width: 80%;
    margin: 0 auto; }
  .scene.games > section > .CardWrapper {
    max-width: 1200px;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      .scene.games > section > .CardWrapper {
        min-height: 46vh; } }
  .scene.games > section > header {
    margin: 25px auto; }
  .scene.games .CardWrapper {
    padding: 20px 30px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
    @media screen and (min-width: 768px) {
      .scene.games .CardWrapper {
        flex-direction: row; } }
    .scene.games .CardWrapper > .Card {
      width: 100%; }
      @media screen and (min-width: 768px) {
        .scene.games .CardWrapper > .Card {
          width: 30%;
          margin-left: 1.5%;
          margin-right: 1.5%;
          flex-direction: column; }
          .scene.games .CardWrapper > .Card > .picture {
            height: 200px;
            width: 100%;
            background-color: #d2d2d2; }
          .scene.games .CardWrapper > .Card > .title {
            width: 100%; }
            .scene.games .CardWrapper > .Card > .title > a {
              padding: 20px 25px;
              text-align: center; } }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.scene.room {
  background: #FFFFFF !important; }
  .scene.room h1 {
    text-align: center;
    margin-bottom: 5px; }
  .scene.room h2 {
    text-align: center;
    margin-bottom: 25px; }
  .scene.room > section > header > h1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    text-align: left; }
  .scene.room > section > header .Picture {
    height: 250px; }
    @media screen and (min-width: 768px) {
      .scene.room > section > header .Picture {
        height: 51vh; } }
  .scene.room > section > section {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 0px; }
    .scene.room > section > section > .introduction {
      color: #565d74;
      font-size: 1.1em;
      line-height: 1.2em;
      margin-bottom: 45px; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.Picture {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100px; }
  .Picture img {
    visibility: hidden;
    max-height: 100%;
    max-width: 100%; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.scene.room-object-identity {
  background: #fff;
  /* required snapPuzzle styles */ }
  .scene.room-object-identity.has-audio .AudioWrapper {
    display: block; }
  .scene.room-object-identity.has-audio footer {
    margin-bottom: 140px; }
    @media screen and (min-width: 768px) {
      .scene.room-object-identity.has-audio footer {
        margin-bottom: 0; } }
  .scene.room-object-identity footer {
    margin-bottom: 30px; }
  @media screen and (min-width: 768px) {
    .scene.room-object-identity footer {
      margin-bottom: 0px; }
      .scene.room-object-identity footer > div {
        max-width: 95%; }
      .scene.room-object-identity footer .ButtonWrapper {
        text-align: left;
        top: 20px; } }
  .scene.room-object-identity .AudioWrapper {
    display: none;
    background: #21283d;
    position: fixed;
    bottom: 0px;
    z-index: 100;
    width: 100%; }
    @media screen and (min-width: 768px) {
      .scene.room-object-identity .AudioWrapper {
        position: absolute;
        bottom: auto;
        margin-top: 87vh;
        width: 50% !important;
        margin-left: 50%; } }
    .scene.room-object-identity .AudioWrapper > div {
      padding: 10px 20px; }
      @media screen and (min-width: 768px) {
        .scene.room-object-identity .AudioWrapper > div {
          padding: 0px 20px 15px; } }
      .scene.room-object-identity .AudioWrapper > div .plyr__controls {
        background: transparent;
        color: #fff; }
  .scene.room-object-identity h1 {
    text-align: center;
    margin-bottom: 5px; }
  .scene.room-object-identity h2 {
    text-align: center;
    margin-bottom: 25px; }
  .scene.room-object-identity .puzzleHolder {
    background-color: #d2d2d2;
    padding: 20px 0 30px; }
    .scene.room-object-identity .puzzleHolder > div {
      display: flex;
      align-items: center;
      height: 100%; }
      .scene.room-object-identity .puzzleHolder > div > div {
        margin: 0 auto; }
    @media screen and (min-width: 768px) {
      .scene.room-object-identity .puzzleHolder {
        height: 86vh; } }
  .scene.room-object-identity .ContentWrapper {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 768px) {
      .scene.room-object-identity .ContentWrapper {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start; }
        .scene.room-object-identity .ContentWrapper > * {
          width: 50%; } }
    .scene.room-object-identity .ContentWrapper .my-tabs {
      height: 43vh; }
      @media screen and (min-width: 768px) {
        .scene.room-object-identity .ContentWrapper .my-tabs {
          height: 86vh; } }
      .scene.room-object-identity .ContentWrapper .my-tabs .Picture {
        height: 42vh;
        background-size: auto 95%;
        background-color: #d2d2d2; }
        @media screen and (min-width: 768px) {
          .scene.room-object-identity .ContentWrapper .my-tabs .Picture {
            height: 86vh;
            background-size: contain; } }
      .scene.room-object-identity .ContentWrapper .my-tabs[data-video="true"] {
        height: 50vh; }
        @media screen and (min-width: 768px) {
          .scene.room-object-identity .ContentWrapper .my-tabs[data-video="true"] {
            height: 70vh; }
            .scene.room-object-identity .ContentWrapper .my-tabs[data-video="true"] .Picture {
              height: 82vh; }
            .scene.room-object-identity .ContentWrapper .my-tabs[data-video="true"] .wrapper .rwt__tabpanel {
              height: 82vh; } }
      .scene.room-object-identity .ContentWrapper .my-tabs .wrapper .rwt__tabpanel {
        height: 40vh;
        padding: 0px;
        background-color: #d2d2d2; }
        .scene.room-object-identity .ContentWrapper .my-tabs .wrapper .rwt__tabpanel#two {
          background: #000; }
          .scene.room-object-identity .ContentWrapper .my-tabs .wrapper .rwt__tabpanel#two > div {
            height: 100%;
            display: flex;
            align-items: center; }
          .scene.room-object-identity .ContentWrapper .my-tabs .wrapper .rwt__tabpanel#two .plyr {
            width: 100%; }
        .scene.room-object-identity .ContentWrapper .my-tabs .wrapper .rwt__tabpanel iframe {
          height: 35vh; }
    .scene.room-object-identity .ContentWrapper .content {
      background: #fff;
      display: flex;
      align-items: center; }
      @media screen and (min-width: 768px) {
        .scene.room-object-identity .ContentWrapper .content {
          height: 86vh;
          overflow: auto; } }
      .scene.room-object-identity .ContentWrapper .content > div {
        padding: 20px 30px 0; }
        @media screen and (min-width: 768px) {
          .scene.room-object-identity .ContentWrapper .content > div {
            max-height: 85%;
            overflow: auto;
            padding: 30px 80px;
            line-height: 1.3em; } }
        .scene.room-object-identity .ContentWrapper .content > div h1, .scene.room-object-identity .ContentWrapper .content > div h2 {
          text-align: left;
          margin-bottom: 25px; }
        .scene.room-object-identity .ContentWrapper .content > div p, .scene.room-object-identity .ContentWrapper .content > div li {
          color: #565d74;
          font-size: 1.1em;
          margin-bottom: 30px; }
  @media screen and (min-width: 768px) {
    .scene.room-object-identity footer.Footer.white {
      position: relative;
      z-index: 99;
      margin-top: 0;
      background: #21283d; }
      .scene.room-object-identity footer.Footer.white > div > .TimerWrapper {
        color: #FFFFFF; }
        .scene.room-object-identity footer.Footer.white > div > .TimerWrapper strong {
          color: #c3a77a; } }
  .scene.room-object-identity .snappuzzle-wrap {
    position: relative;
    display: block; }
  .scene.room-object-identity .snappuzzle-pile {
    position: relative; }
  .scene.room-object-identity .snappuzzle-piece {
    cursor: move; }
  .scene.room-object-identity .snappuzzle-slot {
    position: absolute;
    background: #fff;
    opacity: .8; }
  .scene.room-object-identity .snappuzzle-slot-hover {
    background: #eee; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.scene.room-object-map {
  background: #FFFFFF !important; }
  .scene.room-object-map h1 {
    text-align: center;
    margin-bottom: 5px; }
  .scene.room-object-map h2 {
    text-align: center;
    margin-bottom: 25px; }
  .scene.room-object-map > section > h1 {
    margin-top: 20px; }
  .scene.room-object-map > section > header, .scene.room-object-map > section > .cardWrapper {
    width: 80%;
    margin: 0 auto; }
  .scene.room-object-map > section > .CardWrapper {
    max-width: 1200px;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      .scene.room-object-map > section > .CardWrapper {
        min-height: 46vh; } }
  .scene.room-object-map > section > header {
    margin: 25px auto; }
  .scene.room-object-map .CardWrapper {
    padding: 20px 30px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
    @media screen and (min-width: 768px) {
      .scene.room-object-map .CardWrapper {
        flex-direction: row; } }
    .scene.room-object-map .CardWrapper > .Card {
      width: 100%; }
      @media screen and (min-width: 768px) {
        .scene.room-object-map .CardWrapper > .Card {
          width: 30%;
          margin-left: 1.5%;
          margin-right: 1.5%;
          flex-direction: column; }
          .scene.room-object-map .CardWrapper > .Card > .picture {
            height: 200px;
            width: 100%;
            background-color: #d2d2d2; }
          .scene.room-object-map .CardWrapper > .Card > .title {
            width: 100%; }
            .scene.room-object-map .CardWrapper > .Card > .title > a {
              padding: 20px 25px;
              text-align: center; } }
  @media screen and (max-width: 768px) {
    .scene.room-object-map .Footer {
      padding-bottom: 15px; } }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.scene.room-object-type {
  background: #FFFFFF !important; }
  .scene.room-object-type h1 {
    text-align: center;
    margin-bottom: 5px; }
  .scene.room-object-type h2 {
    text-align: center;
    margin-bottom: 25px; }
  .scene.room-object-type > section > h1 {
    margin-top: 20px; }
  .scene.room-object-type > section > header, .scene.room-object-type > section > .cardWrapper {
    width: 80%;
    margin: 0 auto; }
  .scene.room-object-type > section > .CardWrapper {
    max-width: 1200px;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      .scene.room-object-type > section > .CardWrapper {
        min-height: 50vh; } }
  .scene.room-object-type > section > header {
    margin: 25px auto; }
  .scene.room-object-type .CardWrapper {
    padding: 20px 30px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
    @media screen and (min-width: 768px) {
      .scene.room-object-type .CardWrapper {
        flex-direction: row; } }
    .scene.room-object-type .CardWrapper > .Card {
      width: 100%; }
      @media screen and (min-width: 768px) {
        .scene.room-object-type .CardWrapper > .Card {
          width: 30%;
          margin-left: 1.5%;
          margin-right: 1.5%;
          flex-direction: column; }
          .scene.room-object-type .CardWrapper > .Card > .picture {
            height: 200px;
            width: 100%;
            background-color: #d2d2d2; }
          .scene.room-object-type .CardWrapper > .Card > .title {
            width: 100%; }
            .scene.room-object-type .CardWrapper > .Card > .title > a {
              padding: 20px 25px;
              text-align: center; } }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  height: 50px;
  background: #2c3551;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.7); }

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 80px;
  height: 15px;
  left: 15px;
  top: 15px; }

/* Color/shape of burger icon bars */
/* Color/shape of burger icon bars on hover*/
/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px; }

/* Color/shape of close button cross */
.bm-cross {
  background: #c3a77a; }

.bm-menu-wrap {
  position: fixed;
  height: 100vh; }

/* General sidebar styles */
.bm-menu {
  background: #21283d;
  font-size: 1.15em;
  max-height: 100vh; }

/* Wrapper for item list */
.bm-item-list {
  max-height: 100vh; }

.bm-item {
  padding: 15px 30px; }
  .bm-item.logo {
    background: #2c3551;
    max-height: 200px;
    padding-top: 50px;
    text-align: center;
    outline: 0; }
    .bm-item.logo img {
      max-width: 95%; }
  .bm-item.items {
    padding: 25px 30px; }
    .bm-item.items > .menu-item {
      font-size: 1em;
      display: block;
      color: #fff;
      outline: none;
      margin-bottom: 30px;
      text-decoration: none;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      letter-spacing: 1px; }
      .bm-item.items > .menu-item > img {
        max-width: 15px; }
      .bm-item.items > .menu-item > span {
        margin-left: 20px; }

.bm-overlay {
  background: rgba(0, 0, 0, 0.3); }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.page.error-404 {
  background: #21283d !important; }

.scene.error-404 > section {
  background: #21283d;
  padding-bottom: 0px; }
  .scene.error-404 > section > * {
    padding-left: 30px;
    padding-right: 30px; }
  .scene.error-404 > section > header {
    background: #21283d;
    padding: 50px 30px 0px; }
    .scene.error-404 > section > header img {
      max-width: 270px;
      display: block;
      margin: 0 auto;
      margin-bottom: 25px; }
  .scene.error-404 > section > .content {
    max-width: 800px;
    margin: 0 auto; }
    .scene.error-404 > section > .content h1 {
      margin-bottom: 20px; }
    .scene.error-404 > section > .content p, .scene.error-404 > section > .content li, .scene.error-404 > section > .content td {
      font-size: 1.1em;
      color: #FFFFFF; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
  outline: none;
  -webkit-transition: 0.2s all ease-in;
  transition: 0.2s all ease-in;
  border: 0px;
  display: inline-block;
  padding: 5px 20px 5px 20px;
  text-transform: uppercase;
  background: #c3a77a;
  color: #fff !important;
  text-decoration: none;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 1px;
  letter-spacing: 1px;
  border-radius: 0px;
  font-weight: 900; }
  .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
    margin-left: 15px; }
  @media screen and (max-width: 500px) {
    .Button, .Buttoninput[type=submit], .Buttonbutton, a.Button, a.Buttoninput[type=submit], a.Buttonbutton, .gform_button, .gform_buttoninput[type=submit], .gform_buttonbutton {
      display: block;
      text-align: center; }
      .Button + .Button, .Buttoninput[type=submit] + .Button, .Buttonbutton + .Button, a.Button + .Button, a.Buttoninput[type=submit] + .Button, a.Buttonbutton + .Button, .gform_button + .Button, .gform_buttoninput[type=submit] + .Button, .gform_buttonbutton + .Button {
        margin-left: 0;
        margin-top: 20px; } }
  .Button.box-shadow, .Buttoninput[type=submit].box-shadow, .Buttonbutton.box-shadow, a.Button.box-shadow, a.Buttoninput[type=submit].box-shadow, a.Buttonbutton.box-shadow, .gform_button.box-shadow, .gform_buttoninput[type=submit].box-shadow, .gform_buttonbutton.box-shadow {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    display: block;
    max-width: auto;
    width: 100% !important;
    padding: 10px 10px;
    text-align: center; }
  @media screen and (max-width: 48em) {
    .Button.sm-small, .Buttoninput[type=submit].sm-small, .Buttonbutton.sm-small, a.Button.sm-small, a.Buttoninput[type=submit].sm-small, a.Buttonbutton.sm-small, .gform_button.sm-small, .gform_buttoninput[type=submit].sm-small, .gform_buttonbutton.sm-small {
      font-size: 0.9em;
      padding: 8px 10px 8px 10px;
      margin-left: 10px; } }
  .Button.small, .Buttoninput[type=submit].small, .Buttonbutton.small, a.Button.small, a.Buttoninput[type=submit].small, a.Buttonbutton.small, .gform_button.small, .gform_buttoninput[type=submit].small, .gform_buttonbutton.small {
    font-size: 0.9em;
    padding: 8px 10px 8px 10px; }
  .Button.bordered, .Buttoninput[type=submit].bordered, .Buttonbutton.bordered, a.Button.bordered, a.Buttoninput[type=submit].bordered, a.Buttonbutton.bordered, .gform_button.bordered, .gform_buttoninput[type=submit].bordered, .gform_buttonbutton.bordered {
    border: 3px solid #fff;
    outline: 3px solid #c3a77a;
    margin-top: 3px; }
    .Button.bordered:hover, .Buttoninput[type=submit].bordered:hover, .Buttonbutton.bordered:hover, a.Button.bordered:hover, a.Buttoninput[type=submit].bordered:hover, a.Buttonbutton.bordered:hover, .gform_button.bordered:hover, .gform_buttoninput[type=submit].bordered:hover, .gform_buttonbutton.bordered:hover {
      outline: 3px solid #21283d; }
  .Button.no-rounded, .Buttoninput[type=submit].no-rounded, .Buttonbutton.no-rounded, a.Button.no-rounded, a.Buttoninput[type=submit].no-rounded, a.Buttonbutton.no-rounded, .gform_button.no-rounded, .gform_buttoninput[type=submit].no-rounded, .gform_buttonbutton.no-rounded {
    border-radius: 0;
    padding: 8px 20px; }
    .Button.no-rounded.small, .Buttoninput[type=submit].no-rounded.small, .Buttonbutton.no-rounded.small, a.Button.no-rounded.small, a.Buttoninput[type=submit].no-rounded.small, a.Buttonbutton.no-rounded.small, .gform_button.no-rounded.small, .gform_buttoninput[type=submit].no-rounded.small, .gform_buttonbutton.no-rounded.small {
      padding: 8px 10px; }
  .Button.no-background, .Buttoninput[type=submit].no-background, .Buttonbutton.no-background, a.Button.no-background, a.Buttoninput[type=submit].no-background, a.Buttonbutton.no-background, .gform_button.no-background, .gform_buttoninput[type=submit].no-background, .gform_buttonbutton.no-background {
    background: none;
    color: #c3a77a !important; }
    .Button.no-background:hover, .Button.no-background.active, .Buttoninput[type=submit].no-background:hover, .Buttoninput[type=submit].no-background.active, .Buttonbutton.no-background:hover, .Buttonbutton.no-background.active, a.Button.no-background:hover, a.Button.no-background.active, a.Buttoninput[type=submit].no-background:hover, a.Buttoninput[type=submit].no-background.active, a.Buttonbutton.no-background:hover, a.Buttonbutton.no-background.active, .gform_button.no-background:hover, .gform_button.no-background.active, .gform_buttoninput[type=submit].no-background:hover, .gform_buttoninput[type=submit].no-background.active, .gform_buttonbutton.no-background:hover, .gform_buttonbutton.no-background.active {
      color: #141414 !important;
      background: none; }
  .Button:hover, .Button.active, .Buttoninput[type=submit]:hover, .Buttoninput[type=submit].active, .Buttonbutton:hover, .Buttonbutton.active, a.Button:hover, a.Button.active, a.Buttoninput[type=submit]:hover, a.Buttoninput[type=submit].active, a.Buttonbutton:hover, a.Buttonbutton.active, .gform_button:hover, .gform_button.active, .gform_buttoninput[type=submit]:hover, .gform_buttoninput[type=submit].active, .gform_buttonbutton:hover, .gform_buttonbutton.active {
    background: #21283d; }
  .Button.full, .Buttoninput[type=submit].full, .Buttonbutton.full, a.Button.full, a.Buttoninput[type=submit].full, a.Buttonbutton.full, .gform_button.full, .gform_buttoninput[type=submit].full, .gform_buttonbutton.full {
    width: 77%; }
  .Button.is-fullwidth, .Buttoninput[type=submit].is-fullwidth, .Buttonbutton.is-fullwidth, a.Button.is-fullwidth, a.Buttoninput[type=submit].is-fullwidth, a.Buttonbutton.is-fullwidth, .gform_button.is-fullwidth, .gform_buttoninput[type=submit].is-fullwidth, .gform_buttonbutton.is-fullwidth {
    width: 100%; }
  .Button.grey, .Buttoninput[type=submit].grey, .Buttonbutton.grey, a.Button.grey, a.Buttoninput[type=submit].grey, a.Buttonbutton.grey, .gform_button.grey, .gform_buttoninput[type=submit].grey, .gform_buttonbutton.grey {
    color: #888888 !important; }
    .Button.grey:hover, .Button.grey.active, .Buttoninput[type=submit].grey:hover, .Buttoninput[type=submit].grey.active, .Buttonbutton.grey:hover, .Buttonbutton.grey.active, a.Button.grey:hover, a.Button.grey.active, a.Buttoninput[type=submit].grey:hover, a.Buttoninput[type=submit].grey.active, a.Buttonbutton.grey:hover, a.Buttonbutton.grey.active, .gform_button.grey:hover, .gform_button.grey.active, .gform_buttoninput[type=submit].grey:hover, .gform_buttoninput[type=submit].grey.active, .gform_buttonbutton.grey:hover, .gform_buttonbutton.grey.active {
      color: #141414 !important; }
  .Button.revert, .Buttoninput[type=submit].revert, .Buttonbutton.revert, a.Button.revert, a.Buttoninput[type=submit].revert, a.Buttonbutton.revert, .gform_button.revert, .gform_buttoninput[type=submit].revert, .gform_buttonbutton.revert {
    background: #21283d; }
    .Button.revert:hover, .Button.revert.active, .Buttoninput[type=submit].revert:hover, .Buttoninput[type=submit].revert.active, .Buttonbutton.revert:hover, .Buttonbutton.revert.active, a.Button.revert:hover, a.Button.revert.active, a.Buttoninput[type=submit].revert:hover, a.Buttoninput[type=submit].revert.active, a.Buttonbutton.revert:hover, a.Buttonbutton.revert.active, .gform_button.revert:hover, .gform_button.revert.active, .gform_buttoninput[type=submit].revert:hover, .gform_buttoninput[type=submit].revert.active, .gform_buttonbutton.revert:hover, .gform_buttonbutton.revert.active {
      background: #c3a77a; }
  .Button.highlight, .Buttoninput[type=submit].highlight, .Buttonbutton.highlight, a.Button.highlight, a.Buttoninput[type=submit].highlight, a.Buttonbutton.highlight, .gform_button.highlight, .gform_buttoninput[type=submit].highlight, .gform_buttonbutton.highlight {
    background: #e8165d;
    color: #fff !important; }
    .Button.highlight:hover, .Button.highlight.active, .Buttoninput[type=submit].highlight:hover, .Buttoninput[type=submit].highlight.active, .Buttonbutton.highlight:hover, .Buttonbutton.highlight.active, a.Button.highlight:hover, a.Button.highlight.active, a.Buttoninput[type=submit].highlight:hover, a.Buttoninput[type=submit].highlight.active, a.Buttonbutton.highlight:hover, a.Buttonbutton.highlight.active, .gform_button.highlight:hover, .gform_button.highlight.active, .gform_buttoninput[type=submit].highlight:hover, .gform_buttoninput[type=submit].highlight.active, .gform_buttonbutton.highlight:hover, .gform_buttonbutton.highlight.active {
      color: #e8165d;
      background: #fff; }
  .Button.is-completed, .Buttoninput[type=submit].is-completed, .Buttonbutton.is-completed, a.Button.is-completed, a.Buttoninput[type=submit].is-completed, a.Buttonbutton.is-completed, .gform_button.is-completed, .gform_buttoninput[type=submit].is-completed, .gform_buttonbutton.is-completed {
    background: #21283d;
    color: #fff !important; }
  .Button.white, .Buttoninput[type=submit].white, .Buttonbutton.white, a.Button.white, a.Buttoninput[type=submit].white, a.Buttonbutton.white, .gform_button.white, .gform_buttoninput[type=submit].white, .gform_buttonbutton.white {
    background: #fff;
    color: #c3a77a !important; }
    .Button.white:hover, .Button.white.active, .Buttoninput[type=submit].white:hover, .Buttoninput[type=submit].white.active, .Buttonbutton.white:hover, .Buttonbutton.white.active, a.Button.white:hover, a.Button.white.active, a.Buttoninput[type=submit].white:hover, a.Buttoninput[type=submit].white.active, a.Buttonbutton.white:hover, a.Buttonbutton.white.active, .gform_button.white:hover, .gform_button.white.active, .gform_buttoninput[type=submit].white:hover, .gform_buttoninput[type=submit].white.active, .gform_buttonbutton.white:hover, .gform_buttonbutton.white.active {
      background: #141414;
      color: #fff !important; }
      .Button.white:hover.go-to-outline, .Button.white.active.go-to-outline, .Buttoninput[type=submit].white:hover.go-to-outline, .Buttoninput[type=submit].white.active.go-to-outline, .Buttonbutton.white:hover.go-to-outline, .Buttonbutton.white.active.go-to-outline, a.Button.white:hover.go-to-outline, a.Button.white.active.go-to-outline, a.Buttoninput[type=submit].white:hover.go-to-outline, a.Buttoninput[type=submit].white.active.go-to-outline, a.Buttonbutton.white:hover.go-to-outline, a.Buttonbutton.white.active.go-to-outline, .gform_button.white:hover.go-to-outline, .gform_button.white.active.go-to-outline, .gform_buttoninput[type=submit].white:hover.go-to-outline, .gform_buttoninput[type=submit].white.active.go-to-outline, .gform_buttonbutton.white:hover.go-to-outline, .gform_buttonbutton.white.active.go-to-outline {
        border: 2px solid #fff;
        color: #fff !important;
        background: rgba(255, 255, 255, 0); }
  .Button.dark, .Buttoninput[type=submit].dark, .Buttonbutton.dark, a.Button.dark, a.Buttoninput[type=submit].dark, a.Buttonbutton.dark, .gform_button.dark, .gform_buttoninput[type=submit].dark, .gform_buttonbutton.dark {
    background: #333333;
    color: #ffffff !important;
    border: 1px solid #333333; }
    .Button.dark:hover, .Buttoninput[type=submit].dark:hover, .Buttonbutton.dark:hover, a.Button.dark:hover, a.Buttoninput[type=submit].dark:hover, a.Buttonbutton.dark:hover, .gform_button.dark:hover, .gform_buttoninput[type=submit].dark:hover, .gform_buttonbutton.dark:hover {
      color: #333333 !important;
      background: rgba(0, 0, 0, 0); }
  .Button.go-to-outline, .Buttoninput[type=submit].go-to-outline, .Buttonbutton.go-to-outline, a.Button.go-to-outline, a.Buttoninput[type=submit].go-to-outline, a.Buttonbutton.go-to-outline, .gform_button.go-to-outline, .gform_buttoninput[type=submit].go-to-outline, .gform_buttonbutton.go-to-outline {
    border: 2px solid transparent; }
  .Button.go-dark:hover, .Button.go-dark.active, .Buttoninput[type=submit].go-dark:hover, .Buttoninput[type=submit].go-dark.active, .Buttonbutton.go-dark:hover, .Buttonbutton.go-dark.active, a.Button.go-dark:hover, a.Button.go-dark.active, a.Buttoninput[type=submit].go-dark:hover, a.Buttoninput[type=submit].go-dark.active, a.Buttonbutton.go-dark:hover, a.Buttonbutton.go-dark.active, .gform_button.go-dark:hover, .gform_button.go-dark.active, .gform_buttoninput[type=submit].go-dark:hover, .gform_buttoninput[type=submit].go-dark.active, .gform_buttonbutton.go-dark:hover, .gform_buttonbutton.go-dark.active {
    background: #141414;
    color: #fff !important; }
  .Button.go-light:hover, .Button.go-light.active, .Buttoninput[type=submit].go-light:hover, .Buttoninput[type=submit].go-light.active, .Buttonbutton.go-light:hover, .Buttonbutton.go-light.active, a.Button.go-light:hover, a.Button.go-light.active, a.Buttoninput[type=submit].go-light:hover, a.Buttoninput[type=submit].go-light.active, a.Buttonbutton.go-light:hover, a.Buttonbutton.go-light.active, .gform_button.go-light:hover, .gform_button.go-light.active, .gform_buttoninput[type=submit].go-light:hover, .gform_buttoninput[type=submit].go-light.active, .gform_buttonbutton.go-light:hover, .gform_buttonbutton.go-light.active {
    background: #fff;
    color: #141414 !important; }
  .Button.go-primary:hover, .Button.go-primary.active, .Buttoninput[type=submit].go-primary:hover, .Buttoninput[type=submit].go-primary.active, .Buttonbutton.go-primary:hover, .Buttonbutton.go-primary.active, a.Button.go-primary:hover, a.Button.go-primary.active, a.Buttoninput[type=submit].go-primary:hover, a.Buttoninput[type=submit].go-primary.active, a.Buttonbutton.go-primary:hover, a.Buttonbutton.go-primary.active, .gform_button.go-primary:hover, .gform_button.go-primary.active, .gform_buttoninput[type=submit].go-primary:hover, .gform_buttoninput[type=submit].go-primary.active, .gform_buttonbutton.go-primary:hover, .gform_buttonbutton.go-primary.active {
    background: #c3a77a;
    color: #fff !important; }
  .Button.go-secondary:hover, .Button.go-secondary.active, .Buttoninput[type=submit].go-secondary:hover, .Buttoninput[type=submit].go-secondary.active, .Buttonbutton.go-secondary:hover, .Buttonbutton.go-secondary.active, a.Button.go-secondary:hover, a.Button.go-secondary.active, a.Buttoninput[type=submit].go-secondary:hover, a.Buttoninput[type=submit].go-secondary.active, a.Buttonbutton.go-secondary:hover, a.Buttonbutton.go-secondary.active, .gform_button.go-secondary:hover, .gform_button.go-secondary.active, .gform_buttoninput[type=submit].go-secondary:hover, .gform_buttoninput[type=submit].go-secondary.active, .gform_buttonbutton.go-secondary:hover, .gform_buttonbutton.go-secondary.active {
    background: #21283d;
    color: #fff !important; }
  .Button.facebook, .Buttoninput[type=submit].facebook, .Buttonbutton.facebook, a.Button.facebook, a.Buttoninput[type=submit].facebook, a.Buttonbutton.facebook, .gform_button.facebook, .gform_buttoninput[type=submit].facebook, .gform_buttonbutton.facebook {
    background: #415e9e !important;
    color: #fff !important;
    border: 2px solid #415e9e; }
    .Button.facebook:hover, .Button.facebook:focus, .Buttoninput[type=submit].facebook:hover, .Buttoninput[type=submit].facebook:focus, .Buttonbutton.facebook:hover, .Buttonbutton.facebook:focus, a.Button.facebook:hover, a.Button.facebook:focus, a.Buttoninput[type=submit].facebook:hover, a.Buttoninput[type=submit].facebook:focus, a.Buttonbutton.facebook:hover, a.Buttonbutton.facebook:focus, .gform_button.facebook:hover, .gform_button.facebook:focus, .gform_buttoninput[type=submit].facebook:hover, .gform_buttoninput[type=submit].facebook:focus, .gform_buttonbutton.facebook:hover, .gform_buttonbutton.facebook:focus {
      background: #fff !important;
      color: #415e9e !important; }
  .Button.fat, .Buttoninput[type=submit].fat, .Buttonbutton.fat, a.Button.fat, a.Buttoninput[type=submit].fat, a.Buttonbutton.fat, .gform_button.fat, .gform_buttoninput[type=submit].fat, .gform_buttonbutton.fat {
    padding: 10px 25px; }
  .Button.has-border, .Buttoninput[type=submit].has-border, .Buttonbutton.has-border, a.Button.has-border, a.Buttoninput[type=submit].has-border, a.Buttonbutton.has-border, .gform_button.has-border, .gform_buttoninput[type=submit].has-border, .gform_buttonbutton.has-border {
    border: 2px solid #FFFFFF; }
  .Button.outline, .Buttoninput[type=submit].outline, .Buttonbutton.outline, a.Button.outline, a.Buttoninput[type=submit].outline, a.Buttonbutton.outline, .gform_button.outline, .gform_buttoninput[type=submit].outline, .gform_buttonbutton.outline {
    background: none;
    border: 2px solid #c3a77a;
    color: #c3a77a !important;
    border-radius: 0px; }
    .Button.outline:hover, .Button.outline.active, .Buttoninput[type=submit].outline:hover, .Buttoninput[type=submit].outline.active, .Buttonbutton.outline:hover, .Buttonbutton.outline.active, a.Button.outline:hover, a.Button.outline.active, a.Buttoninput[type=submit].outline:hover, a.Buttoninput[type=submit].outline.active, a.Buttonbutton.outline:hover, a.Buttonbutton.outline.active, .gform_button.outline:hover, .gform_button.outline.active, .gform_buttoninput[type=submit].outline:hover, .gform_buttoninput[type=submit].outline.active, .gform_buttonbutton.outline:hover, .gform_buttonbutton.outline.active {
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.revert, .Buttoninput[type=submit].outline.revert, .Buttonbutton.outline.revert, a.Button.outline.revert, a.Buttoninput[type=submit].outline.revert, a.Buttonbutton.outline.revert, .gform_button.outline.revert, .gform_buttoninput[type=submit].outline.revert, .gform_buttonbutton.outline.revert {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.revert:hover, .Button.outline.revert.active, .Buttoninput[type=submit].outline.revert:hover, .Buttoninput[type=submit].outline.revert.active, .Buttonbutton.outline.revert:hover, .Buttonbutton.outline.revert.active, a.Button.outline.revert:hover, a.Button.outline.revert.active, a.Buttoninput[type=submit].outline.revert:hover, a.Buttoninput[type=submit].outline.revert.active, a.Buttonbutton.outline.revert:hover, a.Buttonbutton.outline.revert.active, .gform_button.outline.revert:hover, .gform_button.outline.revert.active, .gform_buttoninput[type=submit].outline.revert:hover, .gform_buttoninput[type=submit].outline.revert.active, .gform_buttonbutton.outline.revert:hover, .gform_buttonbutton.outline.revert.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.grey, .Buttoninput[type=submit].outline.grey, .Buttonbutton.outline.grey, a.Button.outline.grey, a.Buttoninput[type=submit].outline.grey, a.Buttonbutton.outline.grey, .gform_button.outline.grey, .gform_buttoninput[type=submit].outline.grey, .gform_buttonbutton.outline.grey {
      border-color: #d2d2d2;
      color: #d2d2d2 !important; }
      .Button.outline.grey:hover, .Button.outline.grey.active, .Buttoninput[type=submit].outline.grey:hover, .Buttoninput[type=submit].outline.grey.active, .Buttonbutton.outline.grey:hover, .Buttonbutton.outline.grey.active, a.Button.outline.grey:hover, a.Button.outline.grey.active, a.Buttoninput[type=submit].outline.grey:hover, a.Buttoninput[type=submit].outline.grey.active, a.Buttonbutton.outline.grey:hover, a.Buttonbutton.outline.grey.active, .gform_button.outline.grey:hover, .gform_button.outline.grey.active, .gform_buttoninput[type=submit].outline.grey:hover, .gform_buttoninput[type=submit].outline.grey.active, .gform_buttonbutton.outline.grey:hover, .gform_buttonbutton.outline.grey.active {
        background: #d2d2d2;
        color: #141414 !important; }
    .Button.outline.highlight, .Buttoninput[type=submit].outline.highlight, .Buttonbutton.outline.highlight, a.Button.outline.highlight, a.Buttoninput[type=submit].outline.highlight, a.Buttonbutton.outline.highlight, .gform_button.outline.highlight, .gform_buttoninput[type=submit].outline.highlight, .gform_buttonbutton.outline.highlight {
      border-color: #e8165d;
      color: #e8165d !important; }
      .Button.outline.highlight:hover, .Button.outline.highlight.active, .Buttoninput[type=submit].outline.highlight:hover, .Buttoninput[type=submit].outline.highlight.active, .Buttonbutton.outline.highlight:hover, .Buttonbutton.outline.highlight.active, a.Button.outline.highlight:hover, a.Button.outline.highlight.active, a.Buttoninput[type=submit].outline.highlight:hover, a.Buttoninput[type=submit].outline.highlight.active, a.Buttonbutton.outline.highlight:hover, a.Buttonbutton.outline.highlight.active, .gform_button.outline.highlight:hover, .gform_button.outline.highlight.active, .gform_buttoninput[type=submit].outline.highlight:hover, .gform_buttoninput[type=submit].outline.highlight.active, .gform_buttonbutton.outline.highlight:hover, .gform_buttonbutton.outline.highlight.active {
        background: #e8165d;
        color: #fff !important; }
    .Button.outline.dark, .Buttoninput[type=submit].outline.dark, .Buttonbutton.outline.dark, a.Button.outline.dark, a.Buttoninput[type=submit].outline.dark, a.Buttonbutton.outline.dark, .gform_button.outline.dark, .gform_buttoninput[type=submit].outline.dark, .gform_buttonbutton.outline.dark {
      border-color: #333333;
      color: #333333 !important; }
      .Button.outline.dark:hover, .Buttoninput[type=submit].outline.dark:hover, .Buttonbutton.outline.dark:hover, a.Button.outline.dark:hover, a.Buttoninput[type=submit].outline.dark:hover, a.Buttonbutton.outline.dark:hover, .gform_button.outline.dark:hover, .gform_buttoninput[type=submit].outline.dark:hover, .gform_buttonbutton.outline.dark:hover {
        background: #333333;
        color: #fff !important; }
    .Button.outline.white, .Buttoninput[type=submit].outline.white, .Buttonbutton.outline.white, a.Button.outline.white, a.Buttoninput[type=submit].outline.white, a.Buttonbutton.outline.white, .gform_button.outline.white, .gform_buttoninput[type=submit].outline.white, .gform_buttonbutton.outline.white {
      border-color: #fff;
      color: #fff !important; }
      .Button.outline.white:hover, .Button.outline.white.active, .Buttoninput[type=submit].outline.white:hover, .Buttoninput[type=submit].outline.white.active, .Buttonbutton.outline.white:hover, .Buttonbutton.outline.white.active, a.Button.outline.white:hover, a.Button.outline.white.active, a.Buttoninput[type=submit].outline.white:hover, a.Buttoninput[type=submit].outline.white.active, a.Buttonbutton.outline.white:hover, a.Buttonbutton.outline.white.active, .gform_button.outline.white:hover, .gform_button.outline.white.active, .gform_buttoninput[type=submit].outline.white:hover, .gform_buttoninput[type=submit].outline.white.active, .gform_buttonbutton.outline.white:hover, .gform_buttonbutton.outline.white.active {
        background: #fff;
        color: #141414 !important; }
    .Button.outline.is-completed, .Buttoninput[type=submit].outline.is-completed, .Buttonbutton.outline.is-completed, a.Button.outline.is-completed, a.Buttoninput[type=submit].outline.is-completed, a.Buttonbutton.outline.is-completed, .gform_button.outline.is-completed, .gform_buttoninput[type=submit].outline.is-completed, .gform_buttonbutton.outline.is-completed {
      border-color: #21283d;
      color: #21283d !important; }
      .Button.outline.is-completed:hover, .Button.outline.is-completed.active, .Buttoninput[type=submit].outline.is-completed:hover, .Buttoninput[type=submit].outline.is-completed.active, .Buttonbutton.outline.is-completed:hover, .Buttonbutton.outline.is-completed.active, a.Button.outline.is-completed:hover, a.Button.outline.is-completed.active, a.Buttoninput[type=submit].outline.is-completed:hover, a.Buttoninput[type=submit].outline.is-completed.active, a.Buttonbutton.outline.is-completed:hover, a.Buttonbutton.outline.is-completed.active, .gform_button.outline.is-completed:hover, .gform_button.outline.is-completed.active, .gform_buttoninput[type=submit].outline.is-completed:hover, .gform_buttoninput[type=submit].outline.is-completed.active, .gform_buttonbutton.outline.is-completed:hover, .gform_buttonbutton.outline.is-completed.active {
        background: #21283d;
        color: #fff !important; }
    .Button.outline.go-dark:hover, .Button.outline.go-dark.active, .Buttoninput[type=submit].outline.go-dark:hover, .Buttoninput[type=submit].outline.go-dark.active, .Buttonbutton.outline.go-dark:hover, .Buttonbutton.outline.go-dark.active, a.Button.outline.go-dark:hover, a.Button.outline.go-dark.active, a.Buttoninput[type=submit].outline.go-dark:hover, a.Buttoninput[type=submit].outline.go-dark.active, a.Buttonbutton.outline.go-dark:hover, a.Buttonbutton.outline.go-dark.active, .gform_button.outline.go-dark:hover, .gform_button.outline.go-dark.active, .gform_buttoninput[type=submit].outline.go-dark:hover, .gform_buttoninput[type=submit].outline.go-dark.active, .gform_buttonbutton.outline.go-dark:hover, .gform_buttonbutton.outline.go-dark.active {
      background: #141414;
      border-color: #141414;
      color: #fff !important; }
    .Button.outline.go-light:hover, .Button.outline.go-light.active, .Buttoninput[type=submit].outline.go-light:hover, .Buttoninput[type=submit].outline.go-light.active, .Buttonbutton.outline.go-light:hover, .Buttonbutton.outline.go-light.active, a.Button.outline.go-light:hover, a.Button.outline.go-light.active, a.Buttoninput[type=submit].outline.go-light:hover, a.Buttoninput[type=submit].outline.go-light.active, a.Buttonbutton.outline.go-light:hover, a.Buttonbutton.outline.go-light.active, .gform_button.outline.go-light:hover, .gform_button.outline.go-light.active, .gform_buttoninput[type=submit].outline.go-light:hover, .gform_buttoninput[type=submit].outline.go-light.active, .gform_buttonbutton.outline.go-light:hover, .gform_buttonbutton.outline.go-light.active {
      border-color: #fff;
      background: #fff;
      color: #141414 !important; }
    .Button.outline.go-primary:hover, .Button.outline.go-primary.active, .Buttoninput[type=submit].outline.go-primary:hover, .Buttoninput[type=submit].outline.go-primary.active, .Buttonbutton.outline.go-primary:hover, .Buttonbutton.outline.go-primary.active, a.Button.outline.go-primary:hover, a.Button.outline.go-primary.active, a.Buttoninput[type=submit].outline.go-primary:hover, a.Buttoninput[type=submit].outline.go-primary.active, a.Buttonbutton.outline.go-primary:hover, a.Buttonbutton.outline.go-primary.active, .gform_button.outline.go-primary:hover, .gform_button.outline.go-primary.active, .gform_buttoninput[type=submit].outline.go-primary:hover, .gform_buttoninput[type=submit].outline.go-primary.active, .gform_buttonbutton.outline.go-primary:hover, .gform_buttonbutton.outline.go-primary.active {
      border-color: #c3a77a;
      background: #c3a77a;
      color: #fff !important; }
    .Button.outline.go-secondary:hover, .Button.outline.go-secondary.active, .Buttoninput[type=submit].outline.go-secondary:hover, .Buttoninput[type=submit].outline.go-secondary.active, .Buttonbutton.outline.go-secondary:hover, .Buttonbutton.outline.go-secondary.active, a.Button.outline.go-secondary:hover, a.Button.outline.go-secondary.active, a.Buttoninput[type=submit].outline.go-secondary:hover, a.Buttoninput[type=submit].outline.go-secondary.active, a.Buttonbutton.outline.go-secondary:hover, a.Buttonbutton.outline.go-secondary.active, .gform_button.outline.go-secondary:hover, .gform_button.outline.go-secondary.active, .gform_buttoninput[type=submit].outline.go-secondary:hover, .gform_buttoninput[type=submit].outline.go-secondary.active, .gform_buttonbutton.outline.go-secondary:hover, .gform_buttonbutton.outline.go-secondary.active {
      border-color: #21283d;
      background: #21283d;
      color: #fff !important; }
  .Button.icon-only, .Buttoninput[type=submit].icon-only, .Buttonbutton.icon-only, a.Button.icon-only, a.Buttoninput[type=submit].icon-only, a.Buttonbutton.icon-only, .gform_button.icon-only, .gform_buttoninput[type=submit].icon-only, .gform_buttonbutton.icon-only {
    background: none;
    color: #c3a77a !important;
    padding: 0; }
    .Button.icon-only .pxm-icon, .Buttoninput[type=submit].icon-only .pxm-icon, .Buttonbutton.icon-only .pxm-icon, a.Button.icon-only .pxm-icon, a.Buttoninput[type=submit].icon-only .pxm-icon, a.Buttonbutton.icon-only .pxm-icon, .gform_button.icon-only .pxm-icon, .gform_buttoninput[type=submit].icon-only .pxm-icon, .gform_buttonbutton.icon-only .pxm-icon {
      margin-left: 0; }
    .Button.icon-only:hover, .Button.icon-only.active, .Buttoninput[type=submit].icon-only:hover, .Buttoninput[type=submit].icon-only.active, .Buttonbutton.icon-only:hover, .Buttonbutton.icon-only.active, a.Button.icon-only:hover, a.Button.icon-only.active, a.Buttoninput[type=submit].icon-only:hover, a.Buttoninput[type=submit].icon-only.active, a.Buttonbutton.icon-only:hover, a.Buttonbutton.icon-only.active, .gform_button.icon-only:hover, .gform_button.icon-only.active, .gform_buttoninput[type=submit].icon-only:hover, .gform_buttoninput[type=submit].icon-only.active, .gform_buttonbutton.icon-only:hover, .gform_buttonbutton.icon-only.active {
      color: #21283d !important; }
    .Button.icon-only.revert, .Buttoninput[type=submit].icon-only.revert, .Buttonbutton.icon-only.revert, a.Button.icon-only.revert, a.Buttoninput[type=submit].icon-only.revert, a.Buttonbutton.icon-only.revert, .gform_button.icon-only.revert, .gform_buttoninput[type=submit].icon-only.revert, .gform_buttonbutton.icon-only.revert {
      color: #21283d !important; }
      .Button.icon-only.revert:hover, .Button.icon-only.revert.active, .Buttoninput[type=submit].icon-only.revert:hover, .Buttoninput[type=submit].icon-only.revert.active, .Buttonbutton.icon-only.revert:hover, .Buttonbutton.icon-only.revert.active, a.Button.icon-only.revert:hover, a.Button.icon-only.revert.active, a.Buttoninput[type=submit].icon-only.revert:hover, a.Buttoninput[type=submit].icon-only.revert.active, a.Buttonbutton.icon-only.revert:hover, a.Buttonbutton.icon-only.revert.active, .gform_button.icon-only.revert:hover, .gform_button.icon-only.revert.active, .gform_buttoninput[type=submit].icon-only.revert:hover, .gform_buttoninput[type=submit].icon-only.revert.active, .gform_buttonbutton.icon-only.revert:hover, .gform_buttonbutton.icon-only.revert.active {
        background: none;
        color: #c3a77a !important; }
    .Button.icon-only.highlight, .Buttoninput[type=submit].icon-only.highlight, .Buttonbutton.icon-only.highlight, a.Button.icon-only.highlight, a.Buttoninput[type=submit].icon-only.highlight, a.Buttonbutton.icon-only.highlight, .gform_button.icon-only.highlight, .gform_buttoninput[type=submit].icon-only.highlight, .gform_buttonbutton.icon-only.highlight {
      color: #e8165d; }
      .Button.icon-only.highlight:hover, .Button.icon-only.highlight.active, .Buttoninput[type=submit].icon-only.highlight:hover, .Buttoninput[type=submit].icon-only.highlight.active, .Buttonbutton.icon-only.highlight:hover, .Buttonbutton.icon-only.highlight.active, a.Button.icon-only.highlight:hover, a.Button.icon-only.highlight.active, a.Buttoninput[type=submit].icon-only.highlight:hover, a.Buttoninput[type=submit].icon-only.highlight.active, a.Buttonbutton.icon-only.highlight:hover, a.Buttonbutton.icon-only.highlight.active, .gform_button.icon-only.highlight:hover, .gform_button.icon-only.highlight.active, .gform_buttoninput[type=submit].icon-only.highlight:hover, .gform_buttoninput[type=submit].icon-only.highlight.active, .gform_buttonbutton.icon-only.highlight:hover, .gform_buttonbutton.icon-only.highlight.active {
        background: none;
        color: #e8165d !important; }
    .Button.icon-only.go-dark:hover, .Button.icon-only.go-dark.active, .Buttoninput[type=submit].icon-only.go-dark:hover, .Buttoninput[type=submit].icon-only.go-dark.active, .Buttonbutton.icon-only.go-dark:hover, .Buttonbutton.icon-only.go-dark.active, a.Button.icon-only.go-dark:hover, a.Button.icon-only.go-dark.active, a.Buttoninput[type=submit].icon-only.go-dark:hover, a.Buttoninput[type=submit].icon-only.go-dark.active, a.Buttonbutton.icon-only.go-dark:hover, a.Buttonbutton.icon-only.go-dark.active, .gform_button.icon-only.go-dark:hover, .gform_button.icon-only.go-dark.active, .gform_buttoninput[type=submit].icon-only.go-dark:hover, .gform_buttoninput[type=submit].icon-only.go-dark.active, .gform_buttonbutton.icon-only.go-dark:hover, .gform_buttonbutton.icon-only.go-dark.active {
      background: none;
      color: #141414 !important; }
    .Button.icon-only.go-light:hover, .Button.icon-only.go-light.active, .Buttoninput[type=submit].icon-only.go-light:hover, .Buttoninput[type=submit].icon-only.go-light.active, .Buttonbutton.icon-only.go-light:hover, .Buttonbutton.icon-only.go-light.active, a.Button.icon-only.go-light:hover, a.Button.icon-only.go-light.active, a.Buttoninput[type=submit].icon-only.go-light:hover, a.Buttoninput[type=submit].icon-only.go-light.active, a.Buttonbutton.icon-only.go-light:hover, a.Buttonbutton.icon-only.go-light.active, .gform_button.icon-only.go-light:hover, .gform_button.icon-only.go-light.active, .gform_buttoninput[type=submit].icon-only.go-light:hover, .gform_buttoninput[type=submit].icon-only.go-light.active, .gform_buttonbutton.icon-only.go-light:hover, .gform_buttonbutton.icon-only.go-light.active {
      background: none;
      color: #fff !important; }
    .Button.icon-only.go-primary:hover, .Button.icon-only.go-primary.active, .Buttoninput[type=submit].icon-only.go-primary:hover, .Buttoninput[type=submit].icon-only.go-primary.active, .Buttonbutton.icon-only.go-primary:hover, .Buttonbutton.icon-only.go-primary.active, a.Button.icon-only.go-primary:hover, a.Button.icon-only.go-primary.active, a.Buttoninput[type=submit].icon-only.go-primary:hover, a.Buttoninput[type=submit].icon-only.go-primary.active, a.Buttonbutton.icon-only.go-primary:hover, a.Buttonbutton.icon-only.go-primary.active, .gform_button.icon-only.go-primary:hover, .gform_button.icon-only.go-primary.active, .gform_buttoninput[type=submit].icon-only.go-primary:hover, .gform_buttoninput[type=submit].icon-only.go-primary.active, .gform_buttonbutton.icon-only.go-primary:hover, .gform_buttonbutton.icon-only.go-primary.active {
      background: none;
      color: #c3a77a !important; }
    .Button.icon-only.go-secondary:hover, .Button.icon-only.go-secondary.active, .Buttoninput[type=submit].icon-only.go-secondary:hover, .Buttoninput[type=submit].icon-only.go-secondary.active, .Buttonbutton.icon-only.go-secondary:hover, .Buttonbutton.icon-only.go-secondary.active, a.Button.icon-only.go-secondary:hover, a.Button.icon-only.go-secondary.active, a.Buttoninput[type=submit].icon-only.go-secondary:hover, a.Buttoninput[type=submit].icon-only.go-secondary.active, a.Buttonbutton.icon-only.go-secondary:hover, a.Buttonbutton.icon-only.go-secondary.active, .gform_button.icon-only.go-secondary:hover, .gform_button.icon-only.go-secondary.active, .gform_buttoninput[type=submit].icon-only.go-secondary:hover, .gform_buttoninput[type=submit].icon-only.go-secondary.active, .gform_buttonbutton.icon-only.go-secondary:hover, .gform_buttonbutton.icon-only.go-secondary.active {
      background: none;
      color: #21283d !important; }

body {
  line-height: 1.45em;
  font-size: 14px;
  color: #4e4e4e;
  font-family: sans-serif;
  background: #21283d; }

*[data-appear] {
  opacity: 0; }

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  -webkit-font-feature-settings: none;
          font-feature-settings: none;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #2c3551; }

img {
  max-width: 100%; }

strong {
  font-weight: bold; }

.appear-fade-in {
  -webkit-animation: fadeIn 0.35s;
          animation: fadeIn 0.35s; }

.text-center {
  text-align: center; }

.nothing {
  color: #2c3551;
  font-size: 1.1em !important; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#screen {
  max-width: 375px;
  max-height: 667px; }

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex; }

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #c3a77a;
  margin-right: 1rem; }

.rwt__tab {
  cursor: pointer;
  flex: 1 1;
  width: 50%;
  color: #d9d9d9;
  background: #2c3551;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  height: 50px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  color: #fff;
  outline: 0;
  background-color: #2c3551;
  opacity: 1; }

.rwt__tab[aria-selected="true"] {
  position: relative;
  color: #fff;
  opacity: 1; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 5px solid #c3a77a; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 5px solid #c3a77a; }

.rwt__tabpanel {
  padding: 30px 30px 80px; }
  .rwt__tabpanel.fullheight {
    height: calc(100vh - 210px);
    overflow: auto; }
    .rwt__tabpanel.fullheight#two {
      height: calc(100vh - 100px); }

.my-tabs .wrapper {
  background: none; }

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@-webkit-keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes plyr-popup {
  0% {
    opacity: .5;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease; }

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%; }

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto; }

.plyr:focus {
  outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit; }

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  -webkit-animation: plyr-fade-in .3s ease;
          animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  width: 100%; }

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap; }

.plyr__captions .plyr__caption div {
  display: inline; }

.plyr__captions span:empty {
  display: none; }

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px; } }

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px; }

.plyr__control:focus {
  outline: 0; }

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

a.plyr__control {
  text-decoration: none; }

a.plyr__control::after, a.plyr__control::before {
  display: none; }

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15)); }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #c3a77a;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.plyr__control--overlaid svg {
  left: 2px;
  position: relative; }

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #c3a77a; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }

.plyr__controls .plyr__progress__container {
  flex: 1 1; }

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px; }

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto; }

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px; }

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px; }

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0; }

.plyr__controls .plyr__controls__item.plyr__volume {
  padding-right: 5px; }

.plyr__controls .plyr__controls__item.plyr__volume:first-child {
  padding-right: 0; }

.plyr__controls:empty {
  display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,-webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out,-webkit-transform .4s ease-in-out;
  z-index: 3; }

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none; }

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }

.plyr__menu .plyr__control svg {
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

.plyr__menu .plyr__control[aria-expanded=true] svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none; }

.plyr__menu__container {
  -webkit-animation: plyr-popup .2s ease;
          animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3; }

.plyr__menu__container > div {
  overflow: hidden;
  -webkit-transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0; }

.plyr__menu__container [role=menu] {
  padding: 7px; }

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px; }

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0; }

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%; }

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px; }

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px; }

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor; }

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px); }

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px; }

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%; }

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor; }

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%; }

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 16px; }

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
          transform: translateY(-50%) scale(0);
  -webkit-transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: opacity .3s ease,-webkit-transform .3s ease;
  transition: transform .3s ease,opacity .3s ease;
  transition: transform .3s ease,opacity .3s ease,-webkit-transform .3s ease;
  width: 6px; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #c3a77a; }

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
          transform: translateY(-50%) scale(1); }

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1); }

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none; }

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #c3a77a;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  width: 100%; }

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, currentColor), color-stop(0, transparent));
  background-image: -webkit-linear-gradient(left, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(transparent));
  background-image: -webkit-linear-gradient(left, currentColor var(--value, 0), transparent var(--value, 0));
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0)); }

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  -webkit-appearance: none;
  margin-top: -4px; }

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px; }

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px; }

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent; }

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none; }

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  -webkit-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor; }

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 13px;
  position: relative;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 13px;
  margin-top: 0; }

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none; }

.plyr--full-ui input[type=range]:focus {
  outline: 0; }

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0; }

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 10px) scale(0.8);
          transform: translate(-50%, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  white-space: nowrap;
  z-index: 2; }

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  -webkit-transform: translate(-50%, 0) scale(1);
          transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }

.plyr--video.plyr--menu-open {
  overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  -webkit-transform: translateY(-38.28125%);
          transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px); }

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2; }

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0; }

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -webkit-transition: width .2s ease;
  transition: width .2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66); }

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
          animation: plyr-progress 1s linear infinite;
  background-image: -webkit-linear-gradient(135deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative; }

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2; }

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px; } }

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important; }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:fullscreen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

.plyr:fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-webkit-full-screen video {
  height: 100%; }

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-moz-full-screen video {
  height: 100%; }

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px; } }

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }

.plyr:-ms-fullscreen video {
  height: 100%; }

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%); }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }

.plyr--fullscreen-fallback video {
  height: 100%; }

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%; }

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block; }

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none; }

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none; }

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%; }

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3; }

.plyr__ads::after:empty {
  display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(0, 10px) scale(0.8);
          transform: translate(0, 10px) scale(0.8);
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  transition: transform .2s .1s ease,opacity .2s .1s ease,-webkit-transform .2s .1s ease;
  z-index: 2; }

.plyr__preview-thumb--is-shown {
  opacity: 1;
  -webkit-transform: translate(0, 0) scale(1);
          transform: translate(0, 0) scale(1); }

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  z-index: 2; }

.plyr__preview-thumb__image-container {
  background: #b7c5cd;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3; }

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 1; }

.plyr__preview-scrubbing--is-shown {
  opacity: 1; }

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%; }

.plyr--no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.imp-wrap {
  position: relative;
  width: auto;
  height: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.hs-loader-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-main-image {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: none !important;
  display: block !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.imp-zoom-outer-wrap {
  overflow: hidden; }

#imp-fullscreen-wrap .imp-zoom-outer-wrap {
  overflow: visible; }

#imp-fullscreen-wrap .imp-wrap {
  position: inherit; }

.imp-tooltips-container {
  position: relative;
  z-index: 9999999; }

.imp-zoom-outer-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%; }

.imp-zoom-wrap {
  -webkit-transform: scale(1, 1) translate(0, 0);
          transform: scale(1, 1) translate(0, 0);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  width: 100%;
  height: 100%; }

.imp-translate-wrap {
  width: 100%;
  height: 100%; }

.imp-shape-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape {
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-shape-spot, .imp-shape-rect, .imp-shape-oval, .imp-shape-text {
  position: absolute; }

.imp-shape-text {
  cursor: default; }

.imp-shape-spot {
  display: flex;
  justify-content: center;
  align-items: center;
  /* zoom scaling */
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); }

.imp-shape-spot-pin {
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%; }

.imp-shape-icon-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transform: scale(1, 0.25);
          transform: scale(1, 0.25);
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.53+0,0.3+10,0.13+28,0.04+44,0+67 */
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.13) 28%, rgba(0, 0, 0, 0.04) 44%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87000000', endColorstr='#00000000',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

.imp-spot-fontawesome-icon {
  position: relative;
  z-index: 1; }

.imp-spot-glow {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  background: red;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-spot-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

.imp-shape-glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: ShapeGlowAnimation linear 1.5s;
          animation: ShapeGlowAnimation linear 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.imp-no-glow .imp-shape-glow {
  -webkit-animation: none !important;
          animation: none !important;
  opacity: 0 !important; }

@-webkit-keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ShapeGlowAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.imp-shape img {
  width: 100%; }

.imp-tooltip {
  display: none;
  position: absolute;
  z-index: 1;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: sans-serif; }

.imp-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 4;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-tooltip-title {
  display: none; }

.hs-arrow {
  position: absolute; }

.hs-arrow-top {
  left: 50%;
  top: 0;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; }

.hs-arrow-bottom {
  left: 50%;
  top: 100%;
  margin-left: -8px;
  margin-top: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; }

.hs-arrow-right {
  left: 100%;
  top: 50%;
  margin-left: 0;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black; }

.hs-arrow-left {
  left: 0;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid black; }

.imp-tooltip-buffer {
  position: absolute; }

.imp-tooltip-buffer-top {
  left: 0;
  top: -20px;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-bottom {
  left: 0;
  top: 100%;
  width: 100%;
  height: 20px; }

.imp-tooltip-buffer-left {
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-right {
  left: 100%;
  top: 0;
  width: 20px;
  height: 100%; }

.imp-tooltip-buffer-top.imp-tooltip-buffer-large {
  left: 0;
  top: -60px;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-bottom.imp-tooltip-buffer-large {
  left: 0;
  top: 100%;
  width: 100%;
  height: 60px; }

.imp-tooltip-buffer-left.imp-tooltip-buffer-large {
  left: -60px;
  top: 0;
  width: 60px;
  height: 100%; }

.imp-tooltip-buffer-right.imp-tooltip-buffer-large {
  left: 100%;
  top: 0;
  width: 60px;
  height: 100%; }

/* image backgrounds */
.imp-image-backgrounds-container {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.imp-shape-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat; }

/* fullscreen tooltips */
.imp-fullscreen-tooltips-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2147483647; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition-property: none;
  -moz-transition-property: none;
  -webkit-transition-property: none;
  transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip.imp-tooltip-visible {
  opacity: 1 !important;
  z-index: 3;
  transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important; }

.imp-fullscreen-tooltips-container .imp-fullscreen-tooltip .imp-tooltip-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 99; }

/* fullscreen mode, button */
.imp-fullscreen-button {
  position: absolute;
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  background: white;
  border-radius: 5px;
  color: #222;
  z-index: 3;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.imp-fullscreen-button i {
  margin-right: 3px; }

.imp-fullscreen-button-position-0 {
  left: 20px;
  top: 20px; }

.imp-fullscreen-button-position-1 {
  left: 50%;
  top: 20px; }

.imp-fullscreen-button-position-2 {
  right: 20px;
  top: 20px; }

.imp-fullscreen-button-position-3 {
  right: 20px;
  bottom: 20px; }

.imp-fullscreen-button-position-4 {
  left: 50%;
  bottom: 20px; }

.imp-fullscreen-button-position-5 {
  left: 20px;
  bottom: 20px; }

.imp-fullscreen-button-icon-only {
  width: 44px;
  text-align: center;
  font-size: 18px;
  padding: 0; }

.imp-fullscreen-button-icon-only i {
  margin: 0; }

#imp-fullscreen-wrap {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

body.imp-fullscreen-mode {
  overflow: hidden; }

/* navigator */
.imp-ui {
  /* z-index: 2; */ }

.imp-ui-navigator-root {
  position: absolute;
  left: 20px;
  bottom: 20px;
  /* z-index: 1; */
  cursor: pointer;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.imp-ui-navigator-root:active {
  filter: none !important;
  -moz-filter: none !important;
  -webkit-filter: none !important; }

.imp-ui-navigator-background-image-edgefill {
  z-index: 0;
  position: absolute;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-navigator-background-image {
  z-index: 1;
  position: relative;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); }

.imp-ui-navigator-overlay {
  z-index: 2;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  left: 0;
  top: 0; }

.imp-ui-navigator-window-image {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  max-width: 150px;
  max-height: 150px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

.imp-ui-zoom-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  color: #222;
  text-align: center;
  line-height: 20px;
  font-size: 12px !important;
  z-index: 1;
  border-radius: 2px !important;
  border: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }
  .imp-ui-zoom-button .fa.fa-plus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "+"; }
  .imp-ui-zoom-button .fa.fa-minus::after {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    content: "-"; }

.imp-ui-zoom-button-zoom-in {
  bottom: 34px; }

#image-map-pro-container {
  outline: #F0F0F0 solid 10px;
  background-color: #F0F0F0; }

/* layers */
.imp-ui-layers-menu-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1; }

.imp-ui-layer-switch {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 5px 0 0 0; }

.imp-ui-layer-switch-down {
  top: 22px;
  border-radius: 0 0 0 5px; }

.imp-ui-layer-switch i {
  width: 100%;
  color: #222; }

.imp-ui-layer-switch:active {
  background: #0000001a; }

.imp-ui-layers-select {
  z-index: 1;
  padding: 0 20px 0 40px !important;
  outline: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 44px !important;
  height: 44px !important;
  /* !important is necessary because of wp-admin styles */
  /* wordpress fixes */
  vertical-align: inherit;
  margin: 0;
  font-family: inherit;
  font-weight: inherit;
  box-shadow: none;
  background-color: #f8f8f8;
  color: #222;
  outline-color: #222222;
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #a6a6a6; }

/* UI general */
.imp-ui-element {
  cursor: pointer;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000059;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; }

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8); }

.imp-ui-layers-select:active {
  -webkit-filter: none;
          filter: none; }

/* z-indexes */
.imp-main-image {
  z-index: 0; }

.imp-image-backgrounds-container {
  z-index: 1; }

.imp-shape-container {
  z-index: 2; }

.imp-ui-element {
  z-index: 3; }

.imp-ui-scroll-message-wrap {
  z-index: 4; }

/* shapes menu */
.imp-wrap {
  align-self: start; }

.imp-shapes-menu-outer-wrap {
  display: flex; }

.imp-shapes-menu-wrap {
  position: relative;
  width: 240px;
  background: white;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.imp-shapes-menu-wrap.imp-shapes-menu-wrap-with-search-box {
  padding-top: 73px; }

.imp-shapes-menu-wrap-left {
  border-right: 1px solid #dedede; }

.imp-shapes-menu-wrap-right {
  border-left: 1px solid #dedede; }

.imp-shapes-menu-search-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid #dedede;
  background: #f5f5f5;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%; }

.imp-shapes-menu-scroll-wrap {
  flex: 1 1;
  overflow-y: auto; }

.imp-shapes-menu-search-box input {
  font-size: 14px;
  line-height: 40px;
  padding: 0 35px 0 15px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
  border: 1px solid #dedede; }

.imp-shapes-menu-search-box input:focus {
  border: 1px solid #a6a6a6; }

.imp-shapes-menu-search-box i {
  position: absolute;
  right: 24px;
  top: 27px;
  color: #c7c7c7;
  font-size: 18px; }

.imp-shapes-menu-layer-title {
  position: relative;
  padding: 0 20px;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222; }

.imp-shapes-menu-shape-title {
  color: #222;
  position: relative;
  z-index: 0;
  padding: 0 20px;
  line-height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ececec;
  font-family: sans-serif;
  font-size: 14px; }

.imp-shapes-menu-shape-title:hover {
  background: #f9f9f9; }

#imp-fullscreen-wrap .imp-wrap {
  z-index: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap {
  z-index: 1;
  height: 100%;
  position: absolute;
  top: 0; }

.imp-fullscreen-root-wrap {
  flex: 1 1;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-left {
  left: 0; }

#imp-fullscreen-wrap .imp-shapes-menu-wrap.imp-shapes-menu-wrap-right {
  right: 0; }

.imp-fullscreen-root-wrap.imp-shapes-menu-left {
  margin-left: 240px; }

.imp-fullscreen-root-wrap.imp-shapes-menu-right {
  margin-right: 240px; }

.imp-shapes-menu-wrap span.imp-search-highlight {
  background: #FFF05E;
  border-radius: 2px; }

.imp-shapes-menu-wrap .fa-times {
  display: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  right: 15px;
  top: 15px;
  text-align: center;
  line-height: 42px; }

.imp-shapes-menu-wrap.imp-searching .fa-search {
  display: none; }

.imp-shapes-menu-wrap.imp-searching .fa-times {
  display: block;
  color: #222; }

/* Scroll Message */
.imp-ui-scroll-message-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none; }

.imp-ui-scroll-message-wrap-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.imp-ui-scroll-message {
  padding: 10px 30px;
  line-height: 45px;
  font-size: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.66);
  border-radius: 40px;
  font-family: sans-serif; }

.imp-ui-scroll-message-button {
  border: 1px solid white;
  border-radius: 5px;
  display: inline-block;
  line-height: 28px;
  padding: 0 7px;
  margin: 0 6px;
  font-size: 12px; }

html,
body {
  overscroll-behavior-y: contain; }

.scene.splash-screen {
  padding-bottom: 0px;
  padding-top: 0;
  background: #21283d !important; }
  .scene.splash-screen > section {
    background: #21283d; }
    .scene.splash-screen > section #loading {
      max-height: 50vh;
      background-image: url(/gfx/logo/white_logo.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 260px; }

